import { OrdersList, OrderStatusOption } from "components/OrdersList/OrdersList.types";

export const ordersLists: OrdersList[] = [
    { id: 2, name: "Przekazane", className: "ordersaccepted" },
    { id: 3, name: "Produkowane", className: "ordersproduced" },
    { id: 4, name: "Zrealizowane", className: "ordersfinished" },
    { id: 5, name: "Odebrane", className: "ordersreceived" }
];

export const statuses: OrderStatusOption[] = [
    { id: null, name: '', filterName: ''},
    { id: 2, name: "Przekazane", filterName: 'Accepted' },
    { id: 3, name: "Produkowane", filterName: 'Produced' },
    { id: 4, name: "Zrealizowane", filterName: 'Created' },
    { id: 5, name: "Odebrane", filterName: 'Finished' }
];