import { createContext, Dispatch, SetStateAction, ReactNode, useContext, useState } from "react";

export const LoaderContext = createContext<
    { isLoaderVisible: boolean; setIsLoaderVisible: Dispatch<SetStateAction<boolean>> } | undefined
>(undefined);

export function LoaderProvider({ children }: { children: ReactNode }) {
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);

    return (
        <LoaderContext.Provider value={{ isLoaderVisible, setIsLoaderVisible }}>
            {children}
        </LoaderContext.Provider>
    );
}

export function useLoader() {
    const context = useContext(LoaderContext);

    if (context === undefined) {
        throw new Error("useLoader must be used within a LoaderProvider");
    }

    const { isLoaderVisible, setIsLoaderVisible } = context;

    const toggleLoader = () => {
        setIsLoaderVisible((isVisible) => !isVisible);
    };

    const hideLoader = () => {
        setIsLoaderVisible(false);
    };

    const showLoader = () => {
        setIsLoaderVisible(true);
    };

    return { isLoaderVisible, toggleLoader, hideLoader, showLoader };
}
