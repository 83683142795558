import { useState, useEffect } from "react";
import { SelectProperty } from "components/Project/Project/Project.types";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { ListableProperty } from "api";
import { useProject } from "hooks";

export const Select = ({ options: defaultOptions, getOptions, defaultOption, state }: Omit<SelectProperty, 'type'>) => {
    const [options, setOptions] = useState<Array<ListableProperty | null>>(defaultOptions ?? []);
    const [selectedOption, setSelectedOption] = state || useState(defaultOption);
    const [key, setKey] = useState('Id');
    const { project } = useProject();

    function handleChange(event: DropDownListChangeEvent) {
        setSelectedOption(event.value);
    }
    
    useEffect(() => {
        setSelectedOption(defaultOption);
        if(!defaultOptions && getOptions) {
            const promise = getOptions();

            if(promise) {
                promise.then((props) => {
                    const { Products, Colors, Sides, Overlaps } = props || {};

                    if(Colors) {
                        setOptions(Colors);
                    }

                    if(Sides) {
                        setOptions(Sides);
                        setKey('Side');
                    }

                    if(Overlaps) {
                        setOptions(Overlaps);
                        setKey('Overlap');
                    }
    
                    if(Products) {
                        setOptions(Products);
                    }
                });
            }
        }
    }, [getOptions]);

    return (
        <>
            <DropDownList
                textField="Name"
                dataItemKey={key}
                data={options.filter(o => !!o)}
                value={selectedOption}
                onChange={handleChange}
            />
        </>
    );
}