import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {
    ClientsListPage,
    ClientPasswordChangePage,
    ClientPage,
    ClientPasswordResetPage,
    OrdersListPage,
    TemplatesListPage,
    ProjectPage,
} from "pages";
import { Routes } from "const";
import { AuthProvider, NotificationsProvider, LoaderProvider, ProjectProvider } from "hooks";
import { Notifications, Menu, Loader } from "components";
import { CookiesProvider } from "react-cookie";

export const App = () => {
    return (
        <CookiesProvider>
            <AuthProvider>
                <NotificationsProvider>
                    <LoaderProvider>
                        <Router basename="#">
                            <Notifications />

                            <Loader />

                            <Menu />

                            <Switch>
                                <Route exact path={Routes.INDEX}>
                                    <main>
                                        <div id="index"></div>
                                    </main>
                                </Route>
                                <Route exact path={Routes.CLIENT_EDIT}>
                                    <ClientPage />
                                </Route>
                                <Route exact path={Routes.CLIENT_PASSWORD_GENERATE}>
                                    <ClientPasswordResetPage />
                                </Route>
                                <Route exact path={Routes.CLIENT_PASSWORD_CHANGE}>
                                    <ClientPasswordChangePage />
                                </Route>
                                <Route exact path={Routes.ADMIN_CLIENTS}>
                                    <ClientsListPage />
                                </Route>
                                <Route exact path={Routes.ORDERS}>
                                    <OrdersListPage />
                                </Route>
                                <Route exact path={Routes.PROJECT_TEMPLATES}>
                                    <TemplatesListPage />
                                </Route>
                                <Route exact path={Routes.PROJECT}>
                                    <ProjectProvider>
                                        <ProjectPage />
                                    </ProjectProvider>
                                </Route>
                                <Route path="*">
                                    <Redirect to={Routes.INDEX} />
                                </Route>
                            </Switch>
                        </Router>
                    </LoaderProvider>
                </NotificationsProvider>
            </AuthProvider>
        </CookiesProvider>
    );
};
