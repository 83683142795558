import { DoorsComponentProps } from "./Doors.types";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { ProjectForm, ProjectJoin } from "api";

const COLORS: Record<number, string> = {
    3: "#C3C3C3", // Srebrny
    1: "#B97A57", // Brąz
    9: "#B97A57", // Brąz szczotkowany
    7: "#FFC90E", // Złoty
    58: "#FFFFFF", // Biały połysk
    123: 'red' // zaznaczony
};

const SCALE_MODS = [
    {
        label: "0.5x",
        value: 0.05,
    },
    {
        label: "1x",
        value: 0.1,
    },
    {
        label: "2x",
        value: 0.2,
    },
    {
        label: "Dopasuj do okna",
        value: "contain",
    },
];

export const DoorsComponent = ({
    project,
    selectedJoins,
    selectedForm,
    setSelectedJoins,
    setSelectedForm,
}: DoorsComponentProps) => {
    const { Doors, Joins, Forms, Frames, Properties } = project.Project;
    const projectDiv = useRef<HTMLDivElement>(null);
    const [realignedJoin, setRealignedJoin] = useState<ProjectJoin>();
    const [scaleMod, setScaleMod] = useState({
        label: "Dopasuj do okna",
        value: "contain",
    });
    const [scale, setScale] = useState(0);

    function handleScaleChange(event: DropDownListChangeEvent) {
        const mod = event.value;

        if (mod.value === "contain") {
            setScaleToContain();
        } else {
            setScale(mod.value);
        }

        setScaleMod(mod);
    }

    function calculateScale() {
        const containerWidth = projectDiv.current?.clientWidth;
        const containerHeight = projectDiv.current?.clientHeight;

        if (containerWidth && containerHeight) {
            const {
                Height: { Height },
                Width: { Width },
            } = Properties;
            const heightScale = (containerHeight - 20) / Height;
            const widthScale = (containerWidth - 20) / Width;
            const finalScale = heightScale > widthScale ? widthScale : heightScale;

            return finalScale;
        }

        return 0;
    }

    function setScaleToContain() {
        setScale(calculateScale());
    }

    function handleJoinClick(join: ProjectJoin) {
        return () => {
            setSelectedJoins((joins) => {
                const isSelected = !!joins.find(({ JoinId }) => JoinId === join.JoinId);

                if (isSelected) {
                    return joins.filter(({ JoinId }) => JoinId !== join.JoinId);
                }

                return [...joins, join];
            });
            setSelectedForm(undefined);
        };
    }

    function handleFormClick(form: ProjectForm) {
        return () => {
            setSelectedForm((prevForm) => {
                if (prevForm?.FormId === form.FormId) {
                    return undefined;
                }

                return form;
            });
            setSelectedJoins([]);
        };
    }

    useEffect(() => {
        if (scaleMod.value === "contain") {
            setScaleToContain();
        }
    }, [Properties.Width, Properties.Height]);

    return (
        <>
            {/* <DropDownList
                data={SCALE_MODS}
                defaultValue="1x"
                textField="label"
                onChange={handleScaleChange}
                style={{ display: "block" }}
            /> */}

            <div ref={projectDiv} className="project">
                {projectDiv && 
                <svg
                    className="project-view"
                    id="doors"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    // width="100%"
                    // height="400px"
                    viewBox={`0 0 ${scale * Properties.Width.Width} ${scale * Properties.Height.Height}`}
                >
                    <defs id="SvgjsDefs2111">
                        {Forms.map(({ FormId, ProductId, FormOrientationId }) => (
                            <pattern
                                id={`texture-${FormId}-${ProductId}`}
                                key={FormId + 'texture'}
                                x="0"
                                y="0"
                                width="500"
                                height="1000"
                                patternUnits="userSpaceOnUse"
                                patternTransform={FormOrientationId === 2 ? `rotate(90)` : ""}
                            >
                                <image
                                    id="SvgjsImage2113"
                                    xlinkHref={`/Drzwi/Texture/${ProductId}/Download`}
                                    width="500"
                                    height="1000"
                                ></image>
                            </pattern>
                        ))}
                    </defs>
                    {Forms.map((form) => {
                        const { FormId, FormPoints, ToLeft, ProductId } = form;

                        return (
                            <polygon
                                id={FormId}
                                key={FormId}
                                points={FormPoints.map(
                                    ({ X, Y }) =>
                                        `${(scale * (X + ToLeft)).toFixed(2)} ${(scale * Y).toFixed(
                                            2
                                        )}`
                                ).join(" ")}
                                fill={`url(#texture-${FormId}-${ProductId})`}
                                style={{opacity: selectedForm?.FormId === FormId ? '0.5' : ''}}
                                transform="matrix(1,0,0,1,0,0)"
                                onClick={handleFormClick(form)}
                            ></polygon>
                        );
                    })}

                    {Joins.map((join) => {
                        const { JoinId, JoinX1, JoinX2, JoinY1, JoinY2, ToLeft } = join;

                        return (
                            <line
                                id={JoinId}
                                key={JoinId}
                                x1={scale * (JoinX1 + ToLeft)}
                                y1={scale * JoinY1}
                                x2={scale * (JoinX2 + ToLeft)}
                                y2={scale * JoinY2}
                                stroke={COLORS[!!selectedJoins.find(({ JoinId }) => JoinId === join.JoinId) ? 123 : Properties.Color!.Id as number]}
                                strokeWidth="8"
                                transform="matrix(1,0,0,1,0,0)"
                                onClick={handleJoinClick(join)}
                            />
                        );
                    })}

                    {Frames.map(
                        ({ FrameX1, FrameX2, FrameY1, FrameY2, ToLeft, ToRight }, index) => (
                            <line
                                id="SvgjsLine2123"
                                key={FrameX1 + FrameX2 + index}
                                x1={scale * (FrameX1 + ToLeft)}
                                y1={scale * FrameY1}
                                x2={scale * (FrameX2 + ToLeft)}
                                y2={scale * FrameY2}
                                stroke={COLORS[Properties.Color!.Id as number]}
                                strokeWidth="8"
                                transform="matrix(1,0,0,1,0,0)"
                            />
                        )
                    )}
                </svg>
                }
            </div>
        </>
    );
};
