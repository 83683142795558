import { useEffect, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { OrderWindowProps } from './OrderWindow.types';
import { useAuth } from 'hooks';
import { UserType } from 'api';

export const OrderWindow = (props: OrderWindowProps) => {
    const { onClose, selectedOrder } = props;
    const { auth, isUserAdmin, isUserClient } = useAuth();
    const [estimateUrl, setEstimateUrl] = useState<string>();
    const [noteUrl, setNoteUrl] = useState<string>();
    const [assemblyUrl, setAssemblyUrl] = useState<string>();
    const [selectedTab, setSelectedTab] = useState(0);

    function handleTabSelect(event: TabStripSelectEventArguments) {
        setSelectedTab(event.selected);
    }

    useEffect(() => {
        const orderId = selectedOrder.OrderId;
        // TODO: Zmienić adres
        setEstimateUrl(`/Sklep/Order/${orderId}/Download`);
        setNoteUrl(`/Sklep/Order/${orderId}/Note/Download`);
        setAssemblyUrl(`/Sklep/Order/${orderId}/Assembly/Download`);
    }, []);

    return (
        <Window title="Zamówienie" onClose={onClose} initialHeight={800} initialWidth={800}>
            <div className="order">
                <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                    <TabStripTab title={
                        <>
                            Wycena
                        </>
                    }>
                        {estimateUrl && (
                            <iframe width="100%" height="600" src={estimateUrl} />
                        )}
                    </TabStripTab>

                    <TabStripTab title={
                        <>
                            Uwagi
                        </>
                    }>
                        {noteUrl && (
                            <iframe width="100%" height="600" src={noteUrl} />
                        )}
                    </TabStripTab>

                    {isUserAdmin && (
                        <TabStripTab title={
                            <>
                                Rozkrój
                            </>
                        }>
                            {assemblyUrl && (
                                <iframe width="100%" height="600" src={assemblyUrl} />
                            )}
                        </TabStripTab>
                    )}
                </TabStrip>
            </div>
        </Window>
    );
}