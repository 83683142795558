import { useEffect, useState } from "react";
import { Fade } from "@progress/kendo-react-animation";
import {
    NotificationGroup,
    Notification,
} from "@progress/kendo-react-notification";
import { useNotifications, Notification as NotifcationType } from "hooks";
import { NotificationsProps } from "./Notifications.types";

export const Notifications = ({ fadeAfter = 3000 }: NotificationsProps) => {
    const [notifications, setNotifications] = useState<Array<NotifcationType>>(
        []
    );
    const { subscribe } = useNotifications();

    useEffect(() => {
        subscribe((payload) => {
            // TODO: bug z index.js:1 Warning: Cannot update a component (`Notifications`) while rendering a different component (`NotificationsProvider`). To locate the bad setState() call inside
            setNotifications((notifications) => [...notifications, payload]);

            setTimeout(() => {
                setNotifications((notifications) => notifications.filter(({ id }) => id !== payload.id));
            }, fadeAfter);
        });
    }, []);

    const handleNotificationClosed = (id: string) => () => {
        setNotifications((notifications) =>
            notifications.filter((n) => n.id !== id)
        );
    };

    return (
        <div id="notifications">
            <NotificationGroup
                style={{
                    right: 0,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }}
            >
                {notifications.map((notification, index) => (
                    <Fade key={index} enter={true} exit={true}>
                        <Notification
                            type={{ style: notification.payload.type }}
                            closable={true}
                            onClose={handleNotificationClosed(notification.id)}
                        >
                            {notification.payload.message}
                        </Notification>
                    </Fade>
                ))}
            </NotificationGroup>
        </div>
    );
};
