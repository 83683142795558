import { Input, NumericTextBoxChangeEvent, NumericTextBoxBlurEvent, InputChangeEvent } from "@progress/kendo-react-inputs";
import { useState, useEffect, FocusEvent, useRef } from "react";
import { NumericProperty } from "components/Project/Project/Project.types";
import { useProject } from "hooks";

export const Numeric = ({ defaultValue, state }: Omit<NumericProperty, "type">) => {
    const [value, setValue] = state || useState(defaultValue);

    function handleChange(event: InputChangeEvent) {
        const sanitizedValue = Number(event.value.replaceAll(/[^\d]+/g, ""));
        setValue(sanitizedValue);
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <Input
            value={value}
            onChange={handleChange}
        />
    );
}