import { clientAPI, Client, ClientDiscount } from "api";
import { ClientComponent } from "components";
import { NotificationType, useAuth, useLoader, useNotifications } from "hooks";
import { useEffect, useState } from "react";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { Formik } from "formik";

export const ClientsListPage = () => {
    const { isUserAdmin } = useAuth();
    const { notify } = useNotifications();
    const { showLoader, hideLoader } = useLoader();
    const [clients, setClients] = useState<Array<Client>>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [selectedClientDiscount, setSelectedClientDiscount] = useState<ClientDiscount | null>(
        null
    );

    function handleTabSelect(event: TabStripSelectEventArguments) {
        setSelectedTab(event.selected);
    }

    function handleClientSelect(client: Client) {
        return () => {
            setSelectedClient(client);
            setSelectedTab(0);
        };
    }

    async function handleUserUpdate(clientData: { Client: Client }) {
        const editClientResponse = await clientAPI.update(clientData);

        if (editClientResponse.errors) {
            notify({
                type: NotificationType.ERROR,
                message: editClientResponse.body.Errors[0].Message,
            });
        } else {
            const updatedClientData = editClientResponse.body.Client;
            setSelectedClient(updatedClientData);
            setClients((clients) =>
                clients.map((client) => {
                    if (client.ClientId === updatedClientData.ClientId) {
                        return updatedClientData;
                    }

                    return client;
                })
            );
            notify({
                type: NotificationType.SUCCESS,
                message: "Poprawnie zaktualizowano",
            });
        }
    }

    function handleUserAccessUpdate(client: Client, action: "enable" | "disable") {
        return async () => {
            const clientData = { Client: client };
            const shouldEnable = action === "enable";
            const updateAccessResponse = shouldEnable
                ? await clientAPI.enable(clientData)
                : await clientAPI.disable(clientData);

            if (updateAccessResponse.errors) {
                notify({
                    type: NotificationType.ERROR,
                    message: updateAccessResponse.body.Errors[0].Message,
                });
            } else {
                setClients((clients) =>
                    clients.map((c) => {
                        if (c.ClientId === client.ClientId) {
                            return {
                                ...c,
                                ClientStatusId: shouldEnable ? 1 : 0,
                            };
                        }

                        return c;
                    })
                );
                setSelectedClient(
                    (c) =>
                        c && {
                            ...c,
                            ClientStatusId: shouldEnable ? 1 : 0,
                        }
                );
                notify({
                    type: NotificationType.SUCCESS,
                    message: shouldEnable ? "Włączono dostęp" : "Wyłączono dostęp",
                });
            }
        };
    }

    function handleClientDiscountUpdate(clientId: string) {
        return async (clientDiscount: ClientDiscount) => {
            const editDiscountResponse = await clientAPI.updateDiscount(clientId, clientDiscount);

            if (editDiscountResponse.errors) {
                notify({
                    type: NotificationType.ERROR,
                    message: editDiscountResponse.body.Errors[0].Message,
                });
            } else {
                setSelectedClientDiscount(clientDiscount);
                notify({
                    type: NotificationType.SUCCESS,
                    message: "Poprawnie zaktualizowano",
                });
            }
        };
    }

    useEffect(() => {
        showLoader();

        isUserAdmin && clientAPI
            .list()
            .then((clientsListResponse) => {
                if (clientsListResponse.errors) {
                    notify({
                        type: NotificationType.ERROR,
                        message: clientsListResponse.body.Errors[0].Message,
                    });
                } else {
                    setClients(clientsListResponse.body.Clients);
                }
            })
            .finally(() => hideLoader());
    }, []);

    useEffect(() => {
        if (selectedClient && selectedClient.ClientId) {
            clientAPI.getDiscount(selectedClient.ClientId).then((clientDiscountResponse) => {
                if (!clientDiscountResponse.errors) {
                    setSelectedClientDiscount(clientDiscountResponse.body);
                }
            });
        }
    }, [selectedClient]);

    return (
        <main>
            <div id="clients">
                <div className="clients">
                    <table>
                        <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Adres</th>
                            </tr>
                        </thead>

                        <tbody>
                            {clients.length === 0 ? (
                                <tr>
                                    <td>Brak klientów</td>
                                </tr>
                            ) : (
                                clients.map((client) => (
                                    <tr key={client.ClientId} onClick={handleClientSelect(client)}>
                                        <td>{client.ClientName}</td>
                                        <td>
                                            {client.ClientAddressBillingStreet}{" "}
                                            {client.ClientAddressBillingBuildingNo} /{" "}
                                            {client.ClientAddressBillingApartmentNo}
                                            <br />{client.ClientAddressBillingCity}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {selectedClient && selectedClient.ClientId && (
                <div id="client">
                    <TabStrip  selected={selectedTab} onSelect={handleTabSelect}>
                        <TabStripTab title="Dane klienta">
                            <ClientComponent
                                clientDetails={{ Client: selectedClient }}
                                onSubmit={handleUserUpdate}
                            />
                        </TabStripTab>

                        <TabStripTab title={
                            <>
                                Cennik
                            </>
                        }>
                            <div className="client">
                                {selectedClientDiscount ? (
                                    <Formik
                                        enableReinitialize
                                        initialValues={selectedClientDiscount}
                                        onSubmit={handleClientDiscountUpdate(
                                            selectedClient.ClientId
                                        )}
                                    >
                                        {({ values, handleChange, handleBlur, handleSubmit }) => (
                                            <form onSubmit={handleSubmit}>
                                                <fieldset>
                                                    <legend>Cennik</legend>

                                                    <div className="clientdiscount">
                                                        <label>Rabat:</label>
                                                        <input
                                                            name="Client.ClientDiscount"
                                                            type="number"
                                                            value={values.Client.ClientDiscount}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </fieldset>

                                                <nav className="commands">
                                                    <button className="command" type="submit">
                                                        Zapisz
                                                    </button>
                                                </nav>
                                            </form>
                                        )}
                                    </Formik>
                                ) : (
                                    <p>Wczytywanie rabatu ...</p>
                                )}
                            </div>
                        </TabStripTab>

                        <TabStripTab title={
                            <>
                                Status
                            </>
                        }>
                            <div className="client">
                                <form>
                                    <nav className="commands">
                                        <ul className="list">
                                            {selectedClient.ClientStatusId === 1 ? (
                                                <li className="item">
                                                    <button
                                                        className="command"
                                                        type="button"
                                                        onClick={handleUserAccessUpdate(selectedClient, 'disable')}
                                                    >
                                                        Wyłącz dostęp
                                                    </button>
                                                </li>
                                            ) : (
                                                <li className="item">
                                                    <button
                                                        className="command"
                                                        type="button"
                                                        onClick={handleUserAccessUpdate(selectedClient, 'enable')}
                                                    >
                                                        Włącz dostęp
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </nav>
                                </form>
                            </div>
                        </TabStripTab>
                    </TabStrip>
                </div>
            )}
        </main>
    );
};
