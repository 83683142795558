import { authAPI } from "api";
import { User, UserType } from "api/auth/auth.types";
import { Routes } from "const";
import { Formik } from "formik";
import { NotificationType, useAuth, useLoader, useNotifications } from "hooks";
import { Link, useHistory } from "react-router-dom";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from "react";

const defaultValues: User = {
    UserName: "",
    Password: "",
};

export const LoginForm = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { loginUser } = useAuth();
    const { notify } = useNotifications();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    async function handleSubmit(values: User) {
        const isClient = selectedTab === 0;
        const userType = isClient ? UserType.CLIENT : UserType.ADMIN;
        showLoader();
        const res = await authAPI.login(values, userType);
        if (res.errors) {
            notify({
                type: NotificationType.ERROR,
                message: res.body.Errors[0].Message,
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Zalogowano użytkownika",
            });
            loginUser(userType);
            history.push(Routes.INDEX);
        }
        hideLoader();
    }

    function handleTabChange(event: TabStripSelectEventArguments) {
        setSelectedTab(event.selected);
    }

    const Form = () => (
        <Formik initialValues={defaultValues} onSubmit={handleSubmit}>
            {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="username">
                            <input
                                className="textbox"
                                name="UserName"
                                type="text"
                                placeholder="Adres e-mail"
                                value={values.UserName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>

                        <div className="password">
                            <input
                                className="textbox"
                                name="Password"
                                type="password"
                                placeholder="Hasło"
                                value={values.Password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </fieldset>

                    <div className="commands">
                        <button className="command" type="submit">
                            Zaloguj się
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );

    return (
        <div id="login">
            <div className="login">
                <TabStrip selected={selectedTab} onSelect={handleTabChange}>
                    <TabStripTab title={
                        <>
                            Zaloguj się
                        </>
                    }>
                        <Form />

                        <div className="navbar">
                            <ul className="list">
                                <li className="item">
                                    <Link className="link" to={Routes.CLIENT_EDIT}>
                                        Załóż konto
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link className="link" to={Routes.CLIENT_PASSWORD_GENERATE}>
                                        Odśwież hasło
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </TabStripTab>

                    <TabStripTab title={
                        <>
                            <span className="k-icon k-i-admin"></span>
                        </>
                    }>
                        <Form />
                    </TabStripTab>
                </TabStrip>
            </div>
        </div>
    );
};
