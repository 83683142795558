import { ListableProperty, NormalizedProject, NormalizedProjectProperties, NumericProperties, Project, ProjectProperties } from "api";

export const dictionary: Record<string, string> = {
    Height: "Wysokość",
    Width: "Szerokość",
    Doors: "Ilość drzwi",
    Color: "Kolor",
    Side: "Strona",
    BrushBumper: "Szczotka odbojowa",
    BrushNoDust: "Szczotka przeciwkurzowa",
    CarriageLower: "Wózek dolny",
    CarriageUpper: "Wózek górny",
    DoorLock: "Zamek do drzwi",
    ProfileLower: "Profil dolny",
    ProfileUpper: "Profil górny",
    SoftClose: "Samodomykacz drzwi skrajnych",
    SoftCloseCenter: "Samodomykacz centralny",
    SoftCloseInside: "Samodomykacz drzwi środkowych",
    TrackLower: "TrackLower",
    TrackUpper: "TrackUpper",
};

// export function normalizeProject({ Project }: Project): NormalizedProject {
//     const normalizedProperties = Object.entries(Project.Properties).map(([propName, propValue]) => {
//         if(propName in NumericProperties) {
//             return ({
//                 [propName]: (propValue as any)[propName]
//             });
//         }

//         if((propValue as ListableProperty).Name) {
//             return 
//         }
//     })

//     return ({
//         ...Project,
//         Properties: normalizedProperties as any
//     });
// }

export function getPropertiesDiff(prev: ProjectProperties, current: ProjectProperties) {
    return Object.keys(prev).reduce(
        (diffs, key) => {
            const propertyName = key as keyof ProjectProperties;

            if (propertyName === "Width") {
                const prevValue = prev[propertyName];
                const currentValue = current[propertyName];

                if (prevValue.Width !== currentValue.Width) {
                    return [
                        ...diffs,
                        {
                            name: propertyName,
                            prev: prevValue.Width,
                            current: currentValue.Width,
                        },
                    ];
                }
            } else if (propertyName === "Height") {
                const prevValue = prev[propertyName];
                const currentValue = current[propertyName];

                if (prevValue.Height !== currentValue.Height) {
                    return [
                        ...diffs,
                        {
                            name: propertyName,
                            prev: prevValue.Height,
                            current: currentValue.Height,
                        },
                    ];
                }
            } else if (propertyName === "Doors") {
                const prevValue = prev[propertyName];
                const currentValue = current[propertyName];

                if (prevValue.Doors !== currentValue.Doors) {
                    return [
                        ...diffs,
                        {
                            name: propertyName,
                            prev: prevValue.Doors,
                            current: currentValue.Doors,
                        },
                    ];
                }
            } else {
                const prevValue = prev[propertyName];
                const currentValue = current[propertyName];

                if (prevValue && currentValue && prevValue.Id !== currentValue.Id) {
                    return [
                        ...diffs,
                        {
                            name: propertyName,
                            prev: prevValue.Name,
                            current: currentValue.Name,
                        },
                    ];
                }
            }

            return diffs;
        },
        [] as Array<{
            name: string;
            prev: number | string;
            current: number | string;
        }>
    );
}