import { ClientDetails } from "api";
import { Formik, FormikErrors } from "formik";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { useAuth } from "hooks";
import { useEffect } from "react";
import { ClientComponentProps } from "./Client.types";

const defaultValues: ClientDetails = {
    Client: {
        ClientStatusId: 1,
        ClientId: null,
        ClientTaxNo: "",
        ClientName: "",
        ClientAddressBillingPostcode: "",
        ClientAddressBillingCity: "",
        ClientAddressBillingStreet: "",
        ClientAddressBillingBuildingNo: "",
        ClientAddressBillingApartmentNo: "",
        ClientAddressDeliverPostcode: "",
        ClientAddressDeliverCity: "",
        ClientAddressDeliverStreet: "",
        ClientAddressDeliverBuildingNo: "",
        ClientAddressDeliverApartmentNo: "",
        ClientContactEmail: "",
        ClientContactMobile: "",
    },
    Password: {
        ClientPassword: "",
        ClientPassword2: "",
    },
};

export const ClientComponent = (props: ClientComponentProps) => {
    const { onSubmit, clientDetails = defaultValues } = props;

    function handleValidation(values: ClientDetails) {
        let errors: FormikErrors<ClientDetails> = {};

        if(values.Client.ClientId === null && values.Password) {
            const { ClientPassword, ClientPassword2 } = values.Password;
    
            if (ClientPassword !== ClientPassword2) {
                errors.Password = "Hasła muszą być takie same";
            }
        }

        return errors;
    }

    return (
        <Formik enableReinitialize initialValues={clientDetails} onSubmit={onSubmit} validate={handleValidation}>
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                <div className="client">
                    <form onSubmit={handleSubmit}>
                        {values.Client.ClientId === null && (
                            <fieldset>
                                <legend>Dane konta</legend>

                                <div className="clientpassword">
                                    <label>Hasło:</label>
                                    <input
                                        type="password"
                                        name="Password.ClientPassword"
                                        value={values.Password?.ClientPassword}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="clientpassword2">
                                    <label>Powtórz hasło:</label>
                                    <input 
                                        type="password" 
                                        name="Password.ClientPassword2"
                                        value={values.Password?.ClientPassword2}
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        required
                                    />

                                    {errors.Password}
                                </div>
                            </fieldset>
                        )}

                        <fieldset>
                            <legend>Dane kontrahenta</legend>

                            <div className="clientname">
                                <label>Nazwa firmy:</label>
                                <input
                                    type="text"
                                    name="Client.ClientName"
                                    value={values.Client.ClientName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clienttaxno">
                                <label>Numer NIP:</label>
                                <MaskedTextBox
                                    name="Client.ClientTaxNo"
                                    mask="0000000000"
                                    value={values.Client.ClientTaxNo ?? ""}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="clientstreet">
                                <label>Ulica:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressBillingStreet"
                                    value={values.Client.ClientAddressBillingStreet}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientbuildingno">
                                <label>Nr domu:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressBillingBuildingNo"
                                    value={values.Client.ClientAddressBillingBuildingNo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientapartmentno">
                                <label>Nr lokalu:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressBillingApartmentNo"
                                    value={values.Client.ClientAddressBillingApartmentNo ?? ""}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="clientpostcode">
                                <label>Kod poczty:</label>
                                <MaskedTextBox
                                    name="Client.ClientAddressBillingPostcode"
                                    mask="00-000"
                                    value={values.Client.ClientAddressBillingPostcode}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientcity">
                                <label>Miasto:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressBillingCity"
                                    value={values.Client.ClientAddressBillingCity}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </fieldset>
                        
                        <fieldset>
                            <legend>Dane kontaktowe</legend>

                            <div className="clientemail">
                                <label>Adres e-mail:</label>
                                <input
                                    type="email"
                                    name="Client.ClientContactEmail"
                                    value={values.Client.ClientContactEmail}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientphone">
                                <label>Numer telefonu:</label>
                                <MaskedTextBox
                                    name="Client.ClientContactMobile"
                                    mask="+48 000 000 000"
                                    value={values.Client.ClientContactMobile}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Dane korespondencyjne</legend>

                            <div className="clientstreet2">
                                <label>Ulica:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressDeliverStreet"
                                    value={values.Client.ClientAddressDeliverStreet}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientbuildingno2">
                                <label>Nr domu:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressDeliverBuildingNo"
                                    value={values.Client.ClientAddressDeliverBuildingNo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientapartmentno2">
                                <label>Nr lokalu:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressDeliverApartmentNo"
                                    value={values.Client.ClientAddressDeliverApartmentNo ?? ""}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="clientpostcode2">
                                <label>Kod poczty:</label>
                                <MaskedTextBox
                                    name="Client.ClientAddressDeliverPostcode"
                                    mask="00-000"
                                    value={values.Client.ClientAddressDeliverPostcode}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="clientcity2">
                                <label>Miasto:</label>
                                <input
                                    type="text"
                                    name="Client.ClientAddressDeliverCity"
                                    value={values.Client.ClientAddressDeliverCity}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </fieldset>

                        <nav className="commands">
                            <button className="command" type="submit">
                                Zapisz
                            </button>
                        </nav>
                    </form>
                </div>
            )}
        </Formik>
    );
};
