import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { PropertyComponentProps } from "./Property.types";
import { Catalog } from "components/Project/Property/Editables/Catalog/Catalog.component";
import { Select } from "components/Project/Property/Editables/Select/Select.component";
import { Numeric } from "components/Project/Property/Editables/Numeric/Numeric.component";
import { NumericProperty, PropertySchema, SelectProperty } from "../Project/Project.types";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { useProject } from "hooks";

export const PropertyComponent = ({
    withTabs,
    properties = [],
    selectedProperty,
    setSelectedProperty,
    onClose,
}: PropertyComponentProps) => {
    const { updateProjectProperty } = useProject();
    const windowProps = selectedProperty.windowProps!;
    const { property } = windowProps;
    const selectPropertyState = useState((property as SelectProperty | undefined)?.defaultOption);
    const numericPropertyState = useState((property as NumericProperty | undefined)?.defaultValue);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isBlocked, setIsBlocked] = useState(false);

    const handleTabChange = (event: TabStripSelectEventArguments) => {
        !isBlocked && setSelectedTab(event.selected);
    };

    const setNextTab = () =>
        selectedTab === properties.length - 1 ? onClose() : setSelectedTab((tabIndex) => tabIndex + 1);

    const setPrevTab = () => setSelectedTab((tabIndex) => (tabIndex > 0 ? tabIndex - 1 : tabIndex));

    async function handleSubmit() {
        if (property.type === "select") {
            const [value] = selectPropertyState;
            selectedProperty.key && updateProjectProperty(selectedProperty.key, value);
            await property.onChange({ value });
            setNextTab();
            !withTabs && onClose();
        }

        if (property.type === "numeric" && numericPropertyState[0] !== 0) {
            const [value, setValue] = numericPropertyState;
            selectedProperty.key && updateProjectProperty(selectedProperty.key, value);
            await property.onChange({ value }).then(({ Project } = {}) => {
                if (
                    selectedProperty.key &&
                    Project &&
                    Project.Properties[selectedProperty.key] &&
                    Project.Properties[selectedProperty.key][selectedProperty.key] !== value
                ) {
                    setIsBlocked(true);
                    setValue(0);
                } else {
                    setIsBlocked(false);
                    setNextTab();
                    !withTabs && onClose();
                }
            });
        }
    }

    useEffect(() => {
        setSelectedProperty && setSelectedProperty(properties[selectedTab]);
    }, [selectedTab]);

    return (
        <Window
            title={windowProps.title}
            width={windowProps.width ?? 960}
            onClose={!isBlocked ? onClose : undefined}
        >
            {withTabs && (
                <TabStrip selected={selectedTab} onSelect={handleTabChange}>
                    {properties.map(({ linkLabel }, index) => (
                        <TabStripTab key={index} title={<>{linkLabel}</>} />
                    ))}
                </TabStrip>
            )}
            <>
                {(() => {
                    switch (windowProps.property.type) {
                        case "catalog":
                            return <Catalog {...windowProps.property} />;

                        case "select":
                            return (
                                <div className="property">
                                    <div className="value">
                                        <label>{windowProps.title}:</label>
                                        <Select
                                            state={selectPropertyState}
                                            propertyKey={selectedProperty.key}
                                            {...windowProps.property}
                                        />
                                    </div>
                                </div>
                            );

                        case "numeric":
                            return (
                                <div className="property">
                                    <div className="value">
                                        <label>{windowProps.title}:</label>
                                        <Numeric
                                            state={numericPropertyState}
                                            propertyKey={selectedProperty.key}
                                            {...windowProps.property}
                                        />
                                    </div>
                                </div>
                            );
                    }
                })()}
            </>
            {windowProps.property.type !== "catalog" && (
                <WindowActionsBar>
                    {withTabs && (
                        <button
                            type="button"
                            className="k-button k-primary"
                            onClick={setPrevTab}
                            disabled={isBlocked || selectedTab === 0}
                        >
                            Wstecz
                        </button>
                    )}
                    <button type="button" className="k-button k-primary" onClick={handleSubmit}>
                        {withTabs ? "Dalej" : "Ustaw"}
                    </button>
                </WindowActionsBar>
            )}
        </Window>
    );
};
