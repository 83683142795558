import { clientAPI, ClientPasswordResetRequestBody } from "api";
import { Formik, FormikHelpers } from "formik";
import { NotificationType, useNotifications } from "hooks";

export const ClientPasswordResetPage = () => {
    const { notify } = useNotifications();

    const defaultValues: ClientPasswordResetRequestBody = {
        Client: {
            ContactEmail: "",
        },
    };

    async function handleResetPassword(
        values: ClientPasswordResetRequestBody,
        helpers: FormikHelpers<ClientPasswordResetRequestBody>
    ) {
        const response = await clientAPI.resetPassword(values);

        if (response.errors) {
            notify({
                type: NotificationType.ERROR,
                message: response.body.Errors[0].Message,
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Zresetowano hasło",
            });
            helpers.resetForm();
        }
    }

    return (
        <main>
            <div id="password">
                <div className="password">
                    <Formik initialValues={defaultValues} onSubmit={handleResetPassword}>
                        {({ values, handleSubmit, handleChange, handleBlur }) => (
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>Resetuj hasło</legend>

                                    <div className="clientemail">
                                        <label>Adres e-amil:</label>
                                        <input 
                                        type="email" 
                                        name="Client.ContactEmail" 
                                        required 
                                        value={values.Client.ContactEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    </div>
                                </fieldset>

                                <nav className="commands">
                                    <button className="command" type="submit">
                                        Resetuj hasło
                                    </button>
                                </nav>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </main>
    );
};
