import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ListBox } from "@progress/kendo-react-listbox";
import {
    TreeView,
    TreeViewExpandChangeEvent,
    TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import { useState, useEffect, MouseEvent } from "react";
import { Product, TreeGroup } from "api";
import { CatalogProperty } from "components/Project/Project/Project.types";

const mapToTreeView = (options: TreeGroup[]) =>
    options.map((option) => ({
        ...option,
        Groups: option.Groups.map((group) => ({
            ...group,
            selected: false,
        })),
        expanded: false,
    }));

export const Catalog = ({ defaultOptionId, getOptions, onChange }: CatalogProperty) => {
    const [mainGroups, setMainGroups] = useState(mapToTreeView([]));
    const [selectedGroup, setSelectedGroup] = useState<TreeGroup | null>(null);
    const [expandedGroup, setExpandedGroup] = useState<TreeGroup | null>(null);

    function handleItemSelect(data: Product) {
        return (event: MouseEvent<HTMLSpanElement>) => {
            onChange(data);
        }
    }

    function handleTreeItemClick(event: TreeViewItemClickEvent) {
        const prop = event.item as TreeGroup;
        setSelectedGroup(prop);
        setMainGroups((mains) =>
            mains?.map((main) => ({
                ...main,
                Groups: main.Groups.map((group) => ({
                    ...group,
                    selected: group.Id === prop.Id,
                })),
            }))
        );
    }

    function handleTreeItemExpand(event: TreeViewExpandChangeEvent) {
        const prop = event.item as TreeGroup;
        setExpandedGroup(prop);
        setMainGroups((mains) =>
            mains?.map((main) => ({
                ...main,
                expanded: main.Id === prop.Id,
            }))
        );
    }

    useEffect(() => {
        getOptions().then(({ Groups }) => setMainGroups(mapToTreeView(Groups)));
    }, [getOptions]);

    return (
        <div className="gallery">
            <div className="groups">
                <TreeView
                    textField="Name"
                    childrenField="Groups"
                    expandIcons={true}
                    onItemClick={handleTreeItemClick}
                    onExpandChange={handleTreeItemExpand}
                    data={mainGroups}
                />
            </div>

            <div className="images">
                {selectedGroup && (
                    <ListBox
                        data={selectedGroup.Products}
                        textField="CodeUser"
                        item={(props) => {
                            const { Id, Name, Code, CodeUser } = props.dataItem;

                            return (
                                (defaultOptionId === Id ) ? (
                                    <span className="image active" onClick={handleItemSelect(props.dataItem)}>
                                        <img src={`/Drzwi/Texture/${Id}/Download/Mini`} />
                                        {Code}<br />{Name}
                                    </span>
                                ) : (
                                    <span className="image" onClick={handleItemSelect(props.dataItem)}>
                                        <img src={`/Drzwi/Texture/${Id}/Download/Mini`} />
                                        {Code}<br />{Name}
                                    </span>
                                )
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};
