export type ProjectForm = {
    FormId: string;
    FormPoints: [
        { X: number; Y: number },
        { X: number; Y: number },
        { X: number; Y: number },
        { X: number; Y: number }
    ];
    FormOrientationId: 1 | 2;
    ProductId: string;
    ProductName: string;
    DoorId: string;
    DoorSize: { Width: number; Height: number };
    DoorBorder: { Left: number; Right: number; Top: number; Bottom: number };
    ToLeft: number;
    ToRight: number;
};

export type ProjectDoor = {
    DoorId: string;
    DoorTrack: number;
    DoorWidth: null;
    DoorSize: { Width: number; Height: number };
    DoorBorder: { Left: number; Right: number; Top: number; Bottom: number };
    ToLeft: number;
    ToRight: number;
};

export type ProjectJoin = {
    JoinId: string;
    JoinX1: number;
    JoinY1: number;
    JoinX2: number;
    JoinY2: number;
    JoinLocationTop: number | null;
    JoinLocationLeft: number | null;
    JoinLocationRight: number | null;
    JoinLocationBottom: number | null;
    ProductId: string;
    ProductName: string;
    ProductOutsize: number;
    ProductColorId: number;
    ProductColorName: string;
    JoinContainment: { Y1: number; X1: number; X2: number; Y2: number };
    DoorId: string;
    DoorSize: { Width: number; Height: number };
    DoorBorder: { Left: number; Right: number; Top: number; Bottom: number };
    ToLeft: number;
    ToRight: number;
};

export type ListableProperties =
    | "Color"
    | "Overlap"
    | "Side"
    | "BrushBumper"
    | "BrushNoDust"
    | "CarriageLower"
    | "CarriageUpper"
    | "DoorLock"
    | "ProfileLower"
    | "ProfileUpper"
    | "SoftClose"
    | "SoftCloseCenter"
    | "SoftCloseInside"
    | "TrackLower"
    | "TrackUpper";

export enum NumericProperties {
    HEIGHT = "Height",
    WIDTH = "Width",
    DOORS = "Doors",
}

export type ProjectProperties = {
    Height: {
        Height: number;
    };
    Width: {
        Width: number;
    };
    Doors: {
        Doors: number;
    };
} & Record<ListableProperties, ListableProperty | null>;

export type Project = {
    Project: {
        ProjectId: string;
        Template: null;
        Overlap: number;
        Doors: ProjectDoor[];
        Forms: ProjectForm[];
        Joins: ProjectJoin[];
        Frames: [
            {
                FrameX1: number;
                FrameY1: number;
                FrameX2: number;
                FrameY2: number;
                ProductOutsize: number;
                ProductColorId: number;
                ProductColorName: string;
                DoorId: string;
                ToLeft: number;
                ToRight: number;
            }
        ];
        Measures: [
            {
                ToLeft: number;
                ToRight: number;
                MeasureX1: number;
                MeasureY1: number;
                MeasureX2: number;
                MeasureY2: number;
                MeasureLength: number;
            }
        ];
        Properties: ProjectProperties;
    };
};

export type NormalizedProjectProperties = Record<ListableProperties, ListableProperty | null> &
    Record<NumericProperties, number>;

export type NormalizedProject = {
    ProjectId: string;
    Template: null;
    Overlap: number;
    Doors: ProjectDoor[];
    Forms: ProjectForm[];
    Joins: ProjectJoin[];
    Frames: [
        {
            FrameX1: number;
            FrameY1: number;
            FrameX2: number;
            FrameY2: number;
            ProductOutsize: number;
            ProductColorId: number;
            ProductColorName: string;
            DoorId: string;
            ToLeft: number;
            ToRight: number;
        }
    ];
    Measures: [
        {
            ToLeft: number;
            ToRight: number;
            MeasureX1: number;
            MeasureY1: number;
            MeasureX2: number;
            MeasureY2: number;
            MeasureLength: number;
        }
    ];
    Properties: NormalizedProjectProperties;
};

export type Product = {
    Id: string;
    Name: string;
    Code: string;
    CodeUser: string | null;
};

export type TreeGroup = {
    Id: string;
    Name: string;
    Groups: TreeGroup[];
    Products: Product[];
};

export type AvailableTextures = {
    Groups: TreeGroup[];
};

export type ListableProperty = {
    Id: string | number;
    Name: string;
};

export type AvailableProperties = {
    Products?: Array<ListableProperty>;
    Colors?: Array<ListableProperty>;
    Sides?: Array<ListableProperty>;
    Overlaps?: Array<ListableProperty>;
};

export type OrderEstimate = {
    Order: {
        OrderId: string;
        OrderStatusId: number;
        OrderStatusBy: string;
        OrderStatusDate: string;
        ClientId: string;
        ClientTaxNo: string;
        ClientName: string;
        ClientAddressBillingPostcode: string;
        ClientAddressBillingCity: string;
        ClientAddressBilling: string;
        ClientAddressBillingStreet: string;
        ClientAddressBillingBuildingNo: string;
        ClientAddressBillingApartmentNo: number | null;
        ClientAddressDeliverPostcode: string;
        ClientAddressDeliverCity: string;
        ClientAddressDeliver: string;
        ClientAddressDeliverStreet: string;
        ClientAddressDeliverBuildingNo: string;
        ClientAddressDeliverApartmentNo: number | null;
        ClientContactEmail: string;
        ClientContactMobile: string;
        OrderNumber: string;
        OrderNumber2: number | null;
        OrderNote: string;
        OrderDiscount: number;
        OrderPriceNet: number;
        OrderPrice: number;
        OrderFinalPriceNet: number;
        OrderFinalPrice: number;
    };
};

export type Summary = Partial<Pick<OrderEstimate, "Order"> & Pick<Project, "Project">>;

export type Measure = { MeasureLength: number | null };

export type Measures = {
    MeasuresTop: Measure[];
    MeasuresLeft: Measure[];
    MeasuresRight: Measure[];
    MeasuresBottom: Measure[];
};

export type MeasuresPayload = {
    Project: {
        Door: Measures;
    };
};
