import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { Link } from 'react-router-dom';
import { Routes } from "const";
import { useState } from "react";

export const TemplatesListPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    function handleTabChange(event: TabStripSelectEventArguments) {
        setSelectedTab(event.selected);
    }

    return (
        <main>
            <div id="templates">
                <TabStrip selected={selectedTab} onSelect={handleTabChange}>
                    <TabStripTab
                        title={
                            <>
                                <span className="k-icon k-i-slidingdoors"></span>&nbsp;Przesuwne
                            </>
                        }
                    >
                        <div className="templates">
                            <div className="groups">
                                <div className="group">
                                    <header>
                                        <h1>Linia Medium 56</h1>
                                    </header>

                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/4566abe4-0000-0000-885a-6a98b6e33e76?init=true">Classic Plus</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/56de4421-0000-0000-999f-429028990c47?init=true">Afrodyta</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/0bc39355-0000-0000-9885-883e735f6cc4?init=true">Kronos</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/32494847-0000-0000-9c05-e07d024d3f91?init=true">Uranos</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/28853444-0000-0000-aabc-c1f2f6291bf8?init=true">Zeus</Link></li>
                                    </ul>
                                </div>

                                <div className="group">
                                    <header>
                                        <h1>Linia Premium 75</h1>
                                    </header>

                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/5032154e-0000-0000-a87c-631937f59504?init=true">Orion</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/21ca6a62-0000-0000-b91a-e1f1449cfb42?init=true">Eris</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/6d2e86be-0000-0000-a1aa-da712b8649cb?init=true">Juno</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/11597024-0000-0000-be91-479613e1f50e?init=true">Juno Ramowa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/0823a212-0000-0000-b88f-775b13c77911?init=true">Merkury</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/7d7a4275-0000-0000-aa0b-834df4134416?init=true">Neptun</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/7b531ebd-0000-0000-8a68-bc34d84b2632?init=true">Pluton</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/0bb45059-0000-0000-830f-cd64c6b9937d?init=true">Syriusz</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/76058e3e-0000-0000-9f78-74b8d8ce4d03?init=true">Uran</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/744d4d27-0000-0000-8920-89671dc5756b?init=true">Wega</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/70292828-0000-0000-be5a-21faab10af9a?init=true">Wenus</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/24ab8c10-0000-0000-a935-beffcdb9dc63?init=true">Wenus Eko</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/38fb6d6c-0000-0000-ae83-f34e95f34804?init=true">Westa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/79ec2526-0000-0000-96f6-1c603779c32d?init=true">Westa Bis</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/0c834711-0000-0000-ae91-22bd20002bef?init=true">Westa Ramowa</Link></li>
                                    </ul>
                                </div>

                                <div className="group">
                                    <header>
                                        <h1>Linia Exclusive 82</h1>
                                    </header>

                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/66a09667-0000-0000-b4c7-a2edbbcf60a9?init=true">Ares</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/2e05abc3-0000-0000-aa95-d9efc9a2efe3?init=true">Hermes</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/636431bb-0000-0000-8eba-513eb04c7a6f?init=true">Posejdon</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TabStripTab>

                    <TabStripTab
                        title={
                            <>
                                <span className="k-icon k-i-foldingdoors"></span>&nbsp;Składane
                            </>
                        }
                    >
                        <div className="templates">
                            <div className="groups">
                                <div className="group">
                                    <header>
                                        <h1>Linia Tytan</h1>
                                    </header>

                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/306f09ba-0000-0000-aa03-69d28383c383?init=true">Wega</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/49dd35b2-0000-0000-83f1-cebb49d264b0?init=true">Syriusz</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/6b2bcf50-0000-0000-8d45-5a7e7fb343b3?init=true">Westa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/1ba7d5a6-0000-0000-8670-ab231a400579?init=true">Westa Ramowa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/28712676-0000-0000-adcf-f6d189862621?init=true">Ares</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/423db254-0000-0000-800f-5ea54fa9c936?init=true">Hermes</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TabStripTab>

                    <TabStripTab
                        title={
                            <>
                                <span className="k-icon k-i-pullapartdoors"></span>&nbsp;Rozwierane
                            </>
                        }
                    >
                        <div className="templates">
                            <div className="groups">
                                <div className="group">
                                    <header>
                                        <h1>Linia Cyklop</h1>
                                    </header>

                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/027ed7ce-0000-0000-86c5-3217fef42752?init=true">Wega</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/7702a270-0000-0000-9165-a0ad9530194c?init=true">Syriusz</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/1d9e45a9-0000-0000-ae34-e8f68b24de0f?init=true">Westa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/523c2329-0000-0000-9c3e-c691a55e7aeb?init=true">Westa Bis</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/47f8af64-0000-0000-b297-a431a49abc77?init=true">Westa Ramowa</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/508304ad-0000-0000-9f27-d98ba49a3d2a?init=true">Ares</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/4daa08f0-0000-0000-af9d-a1a47369b48c?init=true">Hermes</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TabStripTab>

                    <TabStripTab
                        title={
                            <>
                                <span className="k-icon k-i-pathwaydoors"></span>&nbsp;Przejściowe
                            </>
                        }
                    >
                        <div className="templates">
                            <div className="groups">
                                <div className="group">
                                    <header>
                                        <h1>Linia Tytan</h1>
                                    </header>


                                    <ul className="list-2">
                                        <li className="item-2"><Link className="line" to="/project/4e2b7da7-0000-0000-b098-22abb42afb47?init=true">Neptun</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/3344dfd4-0000-0000-8159-05be9368dc68?init=true">Kronos</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/1518e559-0000-0000-a376-6ec7fa733df7?init=true">Uranos</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/74524731-0000-0000-aa83-45db4a6231c3?init=true">Uran</Link></li>
                                        <li className="item-2"><Link className="line" to="/project/43294feb-0000-0000-8e99-3e3558bbbff5?init=true">Posejdon</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TabStripTab>
                </TabStrip>
            </div>
        </main>
    );
};
