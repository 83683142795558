import { useLoader } from "hooks";

export const Loader = () => {
    const { isLoaderVisible } = useLoader();

    if (!isLoaderVisible) {
        return null;
    }

    return (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
};
