type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key;
          }
        : {
              type: Key;
              payload: M[Key];
          };
};

export enum NotificationActions {
    ADD = "ADD_NOTIFICATION",
    SUBSCRIBE = "SUBSCRIBE_NOTIFICATION",
}

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error'
}

export type NotificationPayload = {
    type: NotificationType;
    message: string;
};

export type Notification = {
    id: string;
    payload: NotificationPayload;
};

export type NotificationSubscriber = (notification: Notification) => void;

export type UseNotificationsState = {
    notifications: Array<Notification>;
    subscribers: Array<NotificationSubscriber>;
};

export 

type NotificationAction = {
    [NotificationActions.ADD]: Notification;
    [NotificationActions.SUBSCRIBE]: NotificationSubscriber;
};

export type NotificationsActions = ActionMap<NotificationAction>[keyof ActionMap<NotificationAction>];
