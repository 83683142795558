import { useEffect, useState } from "react";
import { clientAPI, GetOrdersQueryParams, OrderDetails, ordersAPI, UserType } from "api";
import { OrdersList, OrdersListFilters, OrderWindow } from "components";
import { Routes, statuses } from "const";
import { NotificationType, useAuth, useLoader, useNotifications } from "hooks";
import { OrdersListFiltersState } from "components/OrdersList/OrdersListFilters/OrdersListFilters.types";
import { Link, useHistory, useLocation } from "react-router-dom";

export const OrdersListPage = () => {
    const { isUserAdmin, isUserClient } = useAuth();
    const history = useHistory();
    const { search } = useLocation();
    const { notify } = useNotifications();
    const { showLoader, hideLoader } = useLoader();
    const [orders, setOrders] = useState<OrderDetails[]>([]);
    const [clients, setClients] = useState<Array<{ id: string; name: string }>>([]);
    const [selectedOrder, setSelectedOrder] = useState<OrderDetails | null>(null);
    const [isWindowVisible, setIsWindowVisible] = useState(false);

    async function fetchOrders(queryParams?: GetOrdersQueryParams) {
        showLoader();
        isUserAdmin && clientAPI
            .list()
            .then((clientsListResponse) => {
                if (clientsListResponse.errors) {
                    notify({
                        type: NotificationType.ERROR,
                        message: clientsListResponse.body.Errors[0].Message,
                    });
                } else {
                    setClients(
                        clientsListResponse.body.Clients.map(({ ClientId, ClientName }) => ({
                            name: ClientName,
                            id: ClientId || "",
                        }))
                    );
                }
            });
        ordersAPI
            .getAll(queryParams)
            .then((response) => {
                if (!response.errors) {
                    setOrders(response.body.Orders);
                }
            })
            .finally(() => hideLoader());
    }

    async function handleDelete() {
        if (selectedOrder && confirm(`Czy usunąć zamówienie: ${selectedOrder.OrderNumber}`)) {
            showLoader();
            const response = await ordersAPI.remove({
                Order: selectedOrder,
            });

            if (response.errors) {
                notify({
                    type: NotificationType.ERROR,
                    message: response.body.Errors[0].Message,
                });
            } else {
                notify({
                    type: NotificationType.SUCCESS,
                    message: "Usunięto zamówienie",
                });
                setOrders((orders) =>
                    orders.filter(({ OrderId }) => OrderId !== selectedOrder.OrderId)
                );
                setSelectedOrder(null);
            }
            hideLoader();
        }
    }

    function handleFiltersChange(filters: OrdersListFiltersState) {
        const { client, status, statusDateBegin, statusDateEnd } = filters;
        let queryParams: GetOrdersQueryParams = {};

        if (client.id) {
            queryParams.clientId = client.id;
        }

        if (status.id && statusDateBegin && statusDateEnd) {
            queryParams[
                `order${status.filterName}DateBegin` as keyof GetOrdersQueryParams
            ] = statusDateBegin.toString();
            queryParams[
                `order${status.filterName}DateEnd` as keyof GetOrdersQueryParams
            ] = statusDateEnd.toString();
        }

        fetchOrders(queryParams);
    }

    function toggleWindow() {
        setIsWindowVisible((visible) => !visible);
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        if (orders.length) {
            const searchParams = new URLSearchParams(search);
            const open = searchParams.get("open");

            if (open) {
                const orderToOpen = orders.find(({ OrderId }) => OrderId === open);

                if (orderToOpen) {
                    setSelectedOrder(orderToOpen);
                    toggleWindow();
                }

                history.replace(Routes.ORDERS);
            }
        }
    }, [orders]);

    return (
        <main>
            <div id="orders">
                <div className="toolbartray">
                    <div className="toolbar">
                        {selectedOrder && (
                            <>
                                <div className="print">
                                    <a onClick={toggleWindow}>Drukuj</a>
                                </div>
                                {isUserAdmin && (
                                    <>
                                        <div className="delete">
                                            <a onClick={handleDelete}>Usuń</a>
                                        </div>

                                        <div className="open">
                                            <Link
                                                to={`/project/${selectedOrder.OrderId}?clientId=${selectedOrder.ClientId}`}
                                            >
                                                Otwórz
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    {isUserAdmin && (
                        <OrdersListFilters
                            {...{
                                statuses,
                                clients: clients,
                                onChange: handleFiltersChange,
                            }}
                        />
                    )}
                </div>

                <OrdersList {...{ orders, setOrders, setSelectedOrder, statuses }} />

                {selectedOrder && isWindowVisible && (
                    <OrderWindow onClose={toggleWindow} selectedOrder={selectedOrder} />
                )}
            </div>
        </main>
    );
};
