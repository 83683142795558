import { authAPI, UserType } from "api";
import {
    Dispatch,
    SetStateAction,
    FunctionComponent,
    useState,
    useContext,
    createContext,
} from "react";
import { useCookies } from "react-cookie";
import { AuthContextState } from "./useAuth.types";

export const AuthContext = createContext<
    | {
          auth: AuthContextState;
          setAuth: Dispatch<SetStateAction<AuthContextState>>;
      }
    | undefined
    >(undefined);

export const AuthProvider: FunctionComponent = ({ children }) => {
    const [cookies] = useCookies([".CTOKEN", ".ATOKEN"]);
    const { ".CTOKEN": clientToken, ".ATOKEN": adminToken } = cookies;
    const isLoggedIn = !!(clientToken || adminToken);

    const [auth, setAuth] = useState<AuthContextState>({
        isLoggedIn,
        userType: adminToken ? UserType.ADMIN : UserType.CLIENT,
    });

    return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export function useAuth() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useNotifications must be used within a NotificationsProvider");
    }

    const { auth, setAuth } = context;
    const [cookies, setCookie, removeCookie] = useCookies([".CTOKEN", ".ATOKEN"]);
    const isUserAdmin = auth.userType === UserType.ADMIN;
    const isUserClient = auth.userType === UserType.CLIENT;

    function loginUser(userType: UserType = UserType.CLIENT) {
        setAuth((state) => ({ ...state, userType, isLoggedIn: true }));
    }

    function logoutUser() {
        removeCookie('.CTOKEN', {
            path: '/'
        });
        removeCookie('.ATOKEN', {
            path: '/'
        });
        setAuth((state) => ({ ...state, isLoggedIn: false }));
    }

    return { auth, isUserAdmin, isUserClient, loginUser, logoutUser };
}
