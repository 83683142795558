import { OrdersListFiltersProps, OrdersListFiltersState } from "./OrdersListFilters.types";
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { ReducerWithoutAction, useEffect, useReducer } from "react";
import { useState } from "react";

const initialFilters = {
    status: { id: null, name: '', filterName: '' },
    client: { id: null, name: '' },
    statusDateBegin: null,
    statusDateEnd: null,
};

export const OrdersListFilters = (props: OrdersListFiltersProps) => {
    const { statuses, clients, onChange } = props;
    const [filters, setFilters] = useState<OrdersListFiltersState>(initialFilters);

    function handleStatusChange(event: DropDownListChangeEvent) {
        setFilters((filters) => ({
            ...filters,
            status: event.value
        }));
    }

    function handleClientChange(event: DropDownListChangeEvent) {
        setFilters((filters) => ({
            ...filters,
            client: event.value
        }));
    }

    function handleBeginDateChange(event: DatePickerChangeEvent) {
        setFilters((filters) => ({
            ...filters,
            statusDateBegin: event.value
        }));
    }

    function handleEndDateChange(event: DatePickerChangeEvent) {
        setFilters((filters) => ({
            ...filters,
            statusEndBegin: event.value
        }));
    }

    useEffect(() => {
        onChange(filters);
    }, [filters]);

    return (
        <div className="toolbar">
            <div className="client">
                <DropDownList
                    data={[initialFilters.client, ...clients]}
                    textField="name"
                    dataItemKey="id"
                    value={filters.client}
                    onChange={handleClientChange}
                />
            </div>

            <div className="status">
                <DropDownList
                    data={statuses}
                    textField="name"
                    dataItemKey="id"
                    value={filters.status}
                    onChange={handleStatusChange}
                />
            </div>

            <div className="statusdatebegin">
                <DatePicker
                    value={filters.statusDateBegin}
                    onChange={handleBeginDateChange}
                />
            </div>

            <div className="statusdateclose">
                <DatePicker
                    value={filters.statusDateEnd}
                    onChange={handleEndDateChange}
                />
            </div>
        </div>
    );
}