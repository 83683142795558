import { API, ResponseStatus } from "../client";
import { Client, ClientData, ClientDetails, ClientDiscount, ClientPasswordChangeRequestBody, ClientPasswordResetRequestBody } from "./client.types";

export async function get(): Promise<ResponseStatus<ClientDetails>> {
    return await API.get('/Sklep/ClientApi/00000000-0000-0000-0000-000000000000').json();
}

export async function getDiscount(clientId:string): Promise<ResponseStatus<ClientDiscount>> {
    return await API.get(`/Sklep/ClientApi/${clientId}/Discount`).json(); 
}

export async function list(): Promise<ResponseStatus<{Clients: Array<Client>}>> {
    return await API.get('/Sklep/ClientApi').json();
} 

export async function add(payload: ClientDetails): Promise<ResponseStatus<ClientDetails>> {
    return await API.post('/Sklep/ClientApi', {
        json: payload
    }).json();
}

export async function update(payload: ClientData): Promise<ResponseStatus<ClientData>> {
    return await API.put(`/Sklep/ClientApi`, {
        json: payload
    }).json();
}

export async function updateDiscount(clientId:string, payload: ClientDiscount): Promise<ResponseStatus<ClientDiscount>> {
    return await API.put(`/Sklep/ClientApi/Discount`, {
        json: {
            Client: {
                ...payload.Client,
                ClientId: clientId
            }
        }
    }).json(); 
}

export async function enable(payload: ClientData): Promise<ResponseStatus<null>> {
    return await API.patch(`/Sklep/ClientApi/Status/Enabled`, {
        json: {
            Client: {
                ClientId: payload.Client.ClientId
            }
        }
    }).json();
}

export async function disable(payload: ClientData): Promise<ResponseStatus<null>> {
    return await API.patch(`/Sklep/ClientApi/Status/Disabled`, {
        json: {
            Client: {
                ClientId: payload.Client.ClientId
            }
        }
    }).json();
}

export async function changePassword(payload: ClientPasswordChangeRequestBody): Promise<ResponseStatus<null>> {
    return await API.put('/Sklep/ClientApi/Password', {
        json: payload
    }).json();
}

export async function resetPassword(payload: ClientPasswordResetRequestBody): Promise<ResponseStatus<null>> {
    return await API.post('/Sklep/ClientApi/Password/Generate', {
        json: payload
    }).json();
}