export type Order = {
    Order: OrderDetails
}

export type OrderDetails = {
    OrderId: string,
    OrderNumber: string,
    OrderNumber2: string,
    OrderNote: string,
    OrderStatusId: number,
    OrderStatusBy: string,
    OrderStatusDate: string,
    ClientId: string,
    ClientTaxNo: string,
    ClientName: string,
    ClientAddressBillingPostcode: string,
    ClientAddressBillingCity: string,
    ClientAddressBilling: string,
    ClientAddressBillingStreet: string,
    ClientAddressBillingBuildingNo: string,
    ClientAddressBillingApartmentNo: string,
    ClientAddressDeliverPostcode: string,
    ClientAddressDeliverCity: string,
    ClientAddressDeliverStreet: string,
    ClientAddressDeliverBuildingNo: string,
    ClientContactEmail: string,
    ClientContactMobile: string,
};

export type GetOrdersQueryParams = {
    orderCreatedDateBegin?: string;
    orderCreatedDateClose?: string;
    orderAcceptedDateBegin?: string;
    orderAcceptedDateClose?: string;
    orderProducedDateBegin?: string;
    orderProducedDateClose?: string;
    orderFinishedDateBegin?: string;
    orderFinishedDateClose?: string;
    orderReceivedDateBegin?: string;
    orderReceivedDateClose?: string;
    orderCanceledDateBegin?: string;
    orderCanceledDateClose?: string;
    clientId?: string;
}

export enum OrderStatus {
    ACCEPTED = 2,
    PRODUCED = 3,
    FINISHED = 4,
    RECEIVED = 5,
}
