import { useState } from 'react';
import { ListBox } from '@progress/kendo-react-listbox';
import { ListBoxDragEvent, ListBoxItemClickEvent } from '@progress/kendo-react-listbox/dist/npm/interfaces/ListBoxEvents';
import { ordersAPI, OrderStatus, UserType, OrderDetails } from 'api';
import { OrdersListProps } from 'types';
import { ListItem } from 'components';
import { ordersLists } from 'const';
import { useAuth } from 'hooks';

function orderOfStatus(status: OrderStatus) {
    return ({ OrderStatusId }: OrderDetails) => (OrderStatusId === status);
}

export const OrdersList = (props: OrdersListProps) => {
    const { orders, setOrders, setSelectedOrder } = props;
    const { auth, isUserAdmin, isUserClient } = useAuth();
    const [draggedItem, setDraggedItem] = useState<OrderDetails | null>();

    async function handleItemDrop(e: ListBoxDragEvent) {
        const OrderStatusId = Number(e.target.props.textField) as OrderStatus;

        if (draggedItem) {
            const response = await ordersAPI.setStatus({
                Order: draggedItem
            }, OrderStatusId);

            if (!response.errors) {
                setOrders((orders) => {
                    const os = orders.map(order => {
                        if (order.OrderId === draggedItem.OrderId) {
                            return ({
                                ...draggedItem,
                                OrderStatusId
                            })
                        }

                        return order;
                    });

                    return os;
                });
                setDraggedItem(null);
            }
        }
    }

    function handleDragStart(e: ListBoxDragEvent) {
        setDraggedItem(e.dataItem);
    }

    function handleItemClick(e: ListBoxItemClickEvent) {
        setSelectedOrder(e.dataItem);
    }

    return (
        <div className="orders">
            {ordersLists.map((list, index) => (
                <div className={list.className} key={index}>
                    <header>
                        <h1>{list.name}</h1>
                    </header>

                    {isUserAdmin ? (
                        <ListBox
                            data={orders.filter(orderOfStatus(list.id))}
                            item={ListItem}
                            textField={`${list.id}`}
                            onDrop={handleItemDrop}
                            onDragStart={handleDragStart}
                            onItemClick={handleItemClick}
                        />
                    ) : (
                        <ListBox
                            data={orders.filter(orderOfStatus(list.id))}
                            item={ListItem}
                            textField={`${list.id}`}
                            onItemClick={handleItemClick}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}