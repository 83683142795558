import { Group } from "@progress/kendo-drawing";
import { API, ResponseStatus } from "../client";
import {
    AvailableProperties,
    AvailableTextures,
    ListableProperties,
    OrderEstimate,
    Project,
} from "./project.types";

// PROJECT
export async function get(projectId: string): Promise<ResponseStatus<Project>> {
    return API.get(`/Drzwi/ProjectApi/${projectId}`).json();
}

// DOORS
export async function updateDoorWidth(
    projectId: string,
    doorId: string,
    width: number
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Door/${doorId}/Width`, {
        json: {
            Project: {
                Door: {
                    DoorWidth: width,
                },
            },
        },
    }).json();
}

// FORMS
export async function getFormTextures(
    projectId: string,
    formId: string
): Promise<ResponseStatus<AvailableTextures>> {
    return API.get(`/Drzwi/ProjectApi/${projectId}/Form/${formId}/TextureAvailable`).json();
}

export async function updateFormTexture(
    projectId: string,
    formId: string,
    textureId: string
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Form/${formId}/Texture`, {
        json: {
            Project: {
                Form: {
                    ProductId: textureId,
                },
            },
        },
    }).json();
}

export async function updateFormOrientation(
    projectId: string,
    formId: string,
    orientation: number
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Form/${formId}/Orientation`, {
        json: {
            Project: {
                Form: {
                    FormOrientationId: orientation,
                },
            },
        },
    }).json();
}

// JOINS
export async function addJoin(projectId: string, doorId: string): Promise<ResponseStatus<Project>> {
    return API.post(`/Drzwi/ProjectApi/${projectId}/Door/${doorId}/Join`).json();
}

export async function updateJoinsMeasures(projectId: string, doorId: string, measures: any): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Door/${doorId}/Measure`, {
        json: measures
    }).json();
}

export async function getProfiles(
    projectId: string,
    joinId: string
): Promise<ResponseStatus<AvailableProperties>> {
    return API.get(`/Drzwi/ProjectApi/${projectId}/Join/${joinId}/ProfileAvailable`).json();
}

export async function updateProfile(
    projectId: string,
    joinId: string,
    productId: string
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Join/${joinId}/Profile`, {
        json: {
            Project: {
                Join: {
                    ProductId: productId,
                },
            },
        },
    }).json();
}

export async function mergeJoins(
    projectId: string,
    joinsIds: string[],
    horizontal?: boolean
): Promise<ResponseStatus<Project>> {
    return API.patch(
        `/Drzwi/ProjectApi/${projectId}/Join/Merge${horizontal ? "/Horizontal" : ""}`,
        {
            searchParams: joinsIds.map((id) => ["joinsId", id]),
        }
    ).json();
}

export async function moveJoins(
    projectId: string,
    joinsIds: string[],
    direction: string
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Join/${direction}/Move`, {
        searchParams: joinsIds.map((id) => ["joinsId", id]),
    }).json();
}

export async function updateJoinOrientation(
    projectId: string,
    joinId: string,
    orientation: number
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Door/${joinId}/Orientation`, {
        json: {
            Project: {
                Door: {
                    JoinOrientationId: orientation,
                },
            },
        },
    }).json();
}

export async function updateJoinLocation(
    projectId: string,
    joinId: string,
    location: {
        JoinLocationTop: number | null;
        JoinLocationLeft: number | null;
        JoinLocationRight: number | null;
        JoinLocationBottom: number | null;
    }
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Join/${joinId}/Location`, {
        json: {
            Project: {
                Join: location,
            },
        },
    }).json();
}

export async function removeJoin(
    projectId: string,
    doorId: string,
    joinId: string
): Promise<ResponseStatus<Project>> {
    return API.delete(`/Drzwi/ProjectApi/${projectId}/Door/${doorId}/Join/${joinId}`).json();
}

// PROPERTIES
export async function getProperties(
    projectId: string,
    propertyName: ListableProperties
): Promise<ResponseStatus<AvailableProperties>> {
    return API.get(`/Drzwi/ProjectApi/${projectId}/Property/${propertyName}Available`).json();
}

export async function updateProperty(
    projectId: string,
    propertyName: ListableProperties,
    propertyId: string
): Promise<ResponseStatus<Project>> {
    const propertyUpdateKey = propertyName === "Side" || propertyName === "Overlap" ? propertyName : "Id";

    return API.patch(`/Drzwi/ProjectApi/${projectId}/Property/${propertyName}`, {
        json: {
            Project: {
                Properties: {
                    [propertyName]: {
                        [propertyUpdateKey]: propertyId,
                    },
                },
            },
        },
    }).json();
}

export async function updatePropertyWithValue(
    projectId: string,
    propertyName: string,
    propertyValue: string | number
): Promise<ResponseStatus<Project>> {
    return API.patch(`/Drzwi/ProjectApi/${projectId}/Property/${propertyName}`, {
        json: {
            Project: {
                Properties: {
                    [propertyName]: {
                        [propertyName]: propertyValue,
                    },
                },
            },
        },
    }).json();
}
