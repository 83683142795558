import { OrderEstimate, Project } from "api";
import { API, ResponseStatus } from "../client";
import { GetOrdersQueryParams, Order, OrderDetails, OrderStatus } from "./orders.types";

export async function getAll(queryParams?: GetOrdersQueryParams): Promise<ResponseStatus<{ Orders: Array<OrderDetails>}>> {
    return await API.get('/Sklep/OrderApi', {
        searchParams: queryParams
    }).json();
}

export async function getEstimate(orderId: string): Promise<Blob> {
    return await API.get(`/Sklep/Order/${orderId}/Download`).blob();
}

export async function getNote(orderId: string): Promise<Blob> {
    return await API.get(`/Sklep/Order/${orderId}/Note/Download`).blob();
}

export async function getAssembly(orderId: string): Promise<Blob> {
    return await API.get(`/Sklep/Order/${orderId}/Assembly/Download`).blob();
}

export async function setStatus(order: Order, statusId: OrderStatus): Promise<ResponseStatus<null>> {
    const data = {
        Order: {
            OrderId: order.Order.OrderId
        }
    };

    switch (statusId) {
        case OrderStatus.ACCEPTED:
            return await API.patch('/Sklep/OrderApi/Status/Accepted', {
                json: data
            }).json();
        case OrderStatus.PRODUCED:
            return await API.patch('/Sklep/OrderApi/Status/Produced', {
                json: data
            }).json();
        case OrderStatus.FINISHED:
            return await API.patch('/Sklep/OrderApi/Status/Finished', {
                json: data
            }).json();
        case OrderStatus.RECEIVED:
            return await API.patch('/Sklep/OrderApi/Status/Received', {
                json: data
            }).json();
    }
}

export async function remove(order: Order): Promise<ResponseStatus<null>> {
    return await API.patch('/Sklep/OrderApi/Status/Canceled', {
        json: order
    }).json();
}

export async function estimateOrder(projectId: string, clientId?: string | null): Promise<ResponseStatus<Project>> {
    if(clientId) {
        return API.post(`/Sklep/OrderApi/${projectId}/Import`, {
            searchParams: {
                ClientId: clientId
            }
        }).json(); 
    }
    
    return API.post(`/Sklep/OrderApi/${projectId}/Import`).json();
}

export async function placeOrder(orderId: string, orderNumber: string, note: string): Promise<ResponseStatus<OrderEstimate> | undefined> {
    const orderNumberRequest = API.patch(`/Sklep/OrderApi/${orderId}/Number`, {
        json: {
            Order: {
                OrderNumber: orderNumber
            }
        }
    }).json() as Promise<ResponseStatus<null>>;
    const orderNoteRequest = API.patch(`/Sklep/OrderApi/${orderId}/Note`, {
        json: {
            Order: {
                OrderNote: note
            }
        }
    }).json() as Promise<ResponseStatus<null>>; 
    const [orderNumberResponse, orderNoteResponse] = await Promise.all([orderNumberRequest, orderNoteRequest]);

    if(!orderNumberResponse.errors && !orderNoteResponse.errors) {
        return API.patch(`/Sklep/OrderApi/Status/Accepted`,  {
            json: {
                Order: {
                    OrderId: orderId
                }
            }
        }).json();
    }
}