import { clientAPI } from "api";
import { ClientPasswordChangeRequestBody } from "api/client/client.types";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { NotificationType, useNotifications } from "hooks";

export const ClientPasswordChangePage = () => {
    const { notify } = useNotifications();

    const defaultValues: ClientPasswordChangeRequestBody = {
        Password: {
            ClientNowPassword: "",
            ClientNewPassword: "",
            ClientNewPassword2: "",
        },
    };

    async function handlePasswordChange(
        values: ClientPasswordChangeRequestBody,
        helpers: FormikHelpers<ClientPasswordChangeRequestBody>
    ) {
        const response = await clientAPI.changePassword(values);

        if (response.errors) {
            notify({
                type: NotificationType.ERROR,
                message: response.body.Errors[0].Message,
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Poprawnie zmieniono hasło",
            });
            helpers.resetForm();
        }
    }

    function handleValidation(values: ClientPasswordChangeRequestBody) {
        const { ClientNewPassword, ClientNewPassword2 } = values.Password;
        let errors: FormikErrors<ClientPasswordChangeRequestBody> = {};

        if (ClientNewPassword !== ClientNewPassword2) {
            errors = {
                ...errors,
                Password: {
                    ClientNewPassword2: "Hasła muszą być takie same",
                },
            };
        }

        return errors;
    }

    return (
        <main>
            <div id="password">
                <div className="password">
                    <Formik
                        initialValues={defaultValues}
                        onSubmit={handlePasswordChange}
                        validate={handleValidation}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>Zmień hasło</legend>

                                    <div className="clientnowpassword">
                                        <label>Aktualne hasło:</label>
                                        <input
                                            name="Password.ClientNowPassword"
                                            type="password"
                                            required
                                            value={values.Password.ClientNowPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    <div className="clientnewpassword">
                                        <label>Nowe hasło:</label>
                                        <input
                                            name="Password.ClientNewPassword"
                                            type="password"
                                            required
                                            value={values.Password.ClientNewPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    <div className="clientnewpassword2">
                                        <label>Powtórz hasło:</label>
                                        <input
                                            name="Password.ClientNewPassword2"
                                            type="password"
                                            required
                                            value={values.Password.ClientNewPassword2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        
                                        {errors.Password?.ClientNewPassword2}
                                    </div>
                                </fieldset>

                                <nav className="commands">
                                    <button className="command" type="submit">
                                        Zmień hasło
                                    </button>
                                </nav>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </main>
    );
};
