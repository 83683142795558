import {
    createContext,
    Dispatch,
    ReactNode,
    useContext,
    useReducer
} from "react";
import {
    NotificationsActions,
    NotificationActions,
    NotificationPayload,
    UseNotificationsState,
    NotificationSubscriber,
} from "./useNotifications.types";
import { v4 as uuid } from "uuid";

const initialState = {
    notifications: [],
    subscribers: [],
};

function notificationsReducer(
    state: UseNotificationsState,
    action: NotificationsActions
) {
    switch (action.type) {
        case NotificationActions.ADD:
            state.subscribers.forEach((subscriber) =>
                subscriber(action.payload)
            );

            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        case NotificationActions.SUBSCRIBE:
            return {
                ...state,
                subscribers: [...state.subscribers, action.payload],
            };
        default:
            return state;
    }
}

export const NotificationsContext = createContext<
    | { state: UseNotificationsState; dispatch: Dispatch<NotificationsActions> }
    | undefined
>(undefined);

export function NotificationsProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(notificationsReducer, initialState);

    return (
        <NotificationsContext.Provider value={{ state, dispatch }}>
            {children}
        </NotificationsContext.Provider>
    );
}

export function useNotifications() {
    const context = useContext(NotificationsContext);

    if (context === undefined) {
        throw new Error("useNotifications must be used within a NotificationsProvider");
    }
    
    const { dispatch } = context;

    const notify = (payload: NotificationPayload) =>
        dispatch({
            type: NotificationActions.ADD,
            payload: {
                id: uuid(),
                payload,
            },
        });

    const subscribe = (callback: NotificationSubscriber) =>
        dispatch({
            type: NotificationActions.SUBSCRIBE,
            payload: callback,
        });

    return { notify, subscribe };
}
