import { API, ResponseStatus } from "../client";
import { User, UserType } from "./auth.types";

export async function login(user: User, type: UserType = UserType.CLIENT): Promise<ResponseStatus<null>> {
    const URL = type === UserType.CLIENT ? "/Sklep/AuthenticationApi/Client" : "/Sklep/AuthenticationApi/Admin"
    return API.post(URL, {
        json: user,
    }).json();
}

export async function logout(type: UserType = UserType.CLIENT): Promise<ResponseStatus<null>> {
    const URL = type === UserType.CLIENT ? "/Sklep/AuthenticationApi/Client" : "/Sklep/AuthenticationApi/Admin"
    return API.delete(URL).json();
}