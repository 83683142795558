import ky from "ky";

export type ResponseStatus<T> = {
    errors: false;
    body: T
} | {
    errors: true;
    body: ErrorBody;
};

export type ErrorBody = {
    Errors: {
        Message: string;        
    }[]
};

export const API = ky.create({
    timeout: 2147483647, // max timeout
    hooks: {
        afterResponse: [
            async (request, options, response) => {
                if (response.status >= 500) {
                    const status500Response: ErrorBody = {
                        Errors: [
                            { Message: "Nie mogliśmy wykonać tej operacji. Przepraszamy."}
                        ]
                    };
                    const withErorrsStatus = {
                        errors: true,
                        body: status500Response,
                    };

                    return new Response(JSON.stringify(withErorrsStatus), {
                        ...response,
                        status: 200,
                    });
                }

                if (response.status < 500 && response.status >= 300) {
                    const body: ErrorBody = await response.json();
                    const withErorrsStatus = {
                        errors: true,
                        body,
                    };

                    return new Response(JSON.stringify(withErorrsStatus), {
                        ...response,
                        status: 200,
                    });
                }

                try {
                    const body: any = await response.json();
                    const withSuccessStatus = {
                        errors: false,
                        body,
                    };
    
                    return new Response(
                        JSON.stringify(withSuccessStatus),
                        response
                    );

                // catch .json() fail when parsing empty body
                } catch (error) {
                    const withSuccessStatus = {
                        errors: false,
                        body: response,
                    };
    
                    return new Response(
                        JSON.stringify(withSuccessStatus),
                        response
                    );
                }
            },
        ],
    },
});
