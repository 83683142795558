import { useEffect, useState } from "react";
import { ClientComponent } from "components/Client/Client.component";
import { clientAPI } from "api";
import { ClientDetails } from "api/client/client.types";
import { NotificationType, useAuth, useNotifications } from "hooks";
import { useHistory } from "react-router-dom";
import { Routes } from "const";

export const ClientPage = () => {
    const { auth, isUserAdmin, isUserClient } = useAuth();
    const { notify } = useNotifications();
    const history = useHistory();
    const [clientDetails, setClientDetails] = useState<ClientDetails | undefined>();

    useEffect(() => {
        clientAPI.get().then((response) => {
            const hasClientDetails = !(response.errors || response.body.Client.ClientId === null);

            if (hasClientDetails) {
                const clientDetails = response.body as ClientDetails;
                setClientDetails(clientDetails);
            }
        });
    }, []);

    function handleSubmit(clientDetails: ClientDetails) {
        if (auth.isLoggedIn) {
            handleUpdateUser(clientDetails);
        } else {
            handleRegisterUser(clientDetails);
        }
    }

    async function handleRegisterUser(clientDetails: ClientDetails) {
        const detailsWithNullClientId: ClientDetails = {
            ...clientDetails,
            Client: {
                ...clientDetails.Client,
                ClientId: null
            }
        };
        const addClientResponse = await clientAPI.add(detailsWithNullClientId);

        if (addClientResponse.errors) {
            notify({
                type: NotificationType.ERROR,
                message: addClientResponse.body.Errors[0].Message,
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Utworzono nowego użytkownika",
            });
            history.push(Routes.INDEX);
        }
    }

    async function handleUpdateUser(clientDetails: ClientDetails) {
        const editClientResponse = await clientAPI.update(clientDetails);

        if (editClientResponse.errors) {
            notify({
                type: NotificationType.ERROR,
                message: editClientResponse.body.Errors[0].Message,
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Sukces! Zapisano",
            });
        }
    }

    return (
        <>
            <main>
                <div id="client">
                    <ClientComponent clientDetails={clientDetails} onSubmit={handleSubmit} />
                </div>
            </main>
        </>
    );
};
