import { ListableProperty, Project, ProjectProperties } from "api";
import { useLoader } from "hooks/useLoader/useLoader.hook";
import { useNotifications } from "hooks/useNotifications/useNotifications.hook";
import { NotificationType } from "hooks/useNotifications/useNotifications.types";
import {
    createContext,
    Dispatch,
    SetStateAction,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from "react";
import { HandleProjectPropertyRequestArguments, Request } from "./useProject.types";
import { dictionary, getPropertiesDiff } from "./useProject.utils";

export const ProjectContext = createContext<
    | { project: Project | undefined; setProject: Dispatch<SetStateAction<Project | undefined>> }
    | undefined
>(undefined);

export function ProjectProvider({ children }: { children: ReactNode }) {
    const [project, setProject] = useState<Project>();

    return (
        <ProjectContext.Provider value={{ project, setProject }}>
            {children}
        </ProjectContext.Provider>
    );
}

export function useProject() {
    const context = useContext(ProjectContext);

    if (context === undefined) {
        throw new Error("useLoader must be used within a ProjectProvider");
    }

    const { showLoader, hideLoader } = useLoader();
    const { notify } = useNotifications();
    const { project, setProject } = context;

    async function handleProjectRequest(request: Request<any, Project>, arg?: any) {
        showLoader();

        const response = await request(arg);
        if (response && !response.errors) {
            setProject((prev) => {
                return response.body;
            });
            hideLoader();
            return response.body;
        } else {
            notify({
                type: NotificationType.ERROR,
                message: response?.body.Errors[0].Message || "",
            });
        }

        hideLoader();
    }

    async function handleProjectPropertyRequest({
        request,
        propertyName = "",
    }: HandleProjectPropertyRequestArguments) {
        showLoader();

        const response = await request(null);
        if (response && !response.errors) {
            setProject((prev) => {
                prev &&
                    getPropertiesDiff(
                        prev.Project.Properties,
                        response.body.Project.Properties
                    ).forEach((diff) => {
                        notify({
                            type: NotificationType.SUCCESS,
                            message: `Własność ${dictionary[diff.name]} została zmieniona z ${
                                diff.prev
                            } na ${diff.current}`,
                        });
                    });
                return response.body;
            });
            hideLoader();

            return response.body;
        }

        hideLoader();
    }

    function updateProjectProperty<T>(key: string, value: T) {
        if (typeof value === "number") {
            setProject(
                (project) =>
                    project && {
                        Project: {
                            ...project.Project,
                            Properties: {
                                ...project.Project.Properties,
                                [key]: {
                                    [key]: value,
                                },
                            },
                        },
                    }
            );
        }

        if (typeof value === "object") {
            setProject(
                (project) =>
                    project && {
                        Project: {
                            ...project.Project,
                            Properties: {
                                ...project.Project.Properties,
                                [key]: value,
                            },
                        },
                    }
            );
        }
    }

    return { project, updateProjectProperty, handleProjectRequest, handleProjectPropertyRequest };
}
