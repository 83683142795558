import { authAPI, UserType } from "api";
import { LoginForm } from "components";
import { Routes } from "const";
import { NotificationType, useAuth, useNotifications, useLoader } from "hooks";
import { Link, useRouteMatch, useHistory } from "react-router-dom";

export const Menu = () => {
    const { notify } = useNotifications();
    const { showLoader, hideLoader } = useLoader();
    const { auth, isUserAdmin, isUserClient, logoutUser } = useAuth();
    const nonAuthPathsMatch = useRouteMatch([
            Routes.INDEX,
            Routes.CLIENT_PASSWORD_GENERATE,
            Routes.CLIENT_PASSWORD_CHANGE,
            Routes.CLIENT_EDIT
    ]);
    const history = useHistory();

    async function handleLogout(e: React.MouseEvent) {
        e.preventDefault();
        showLoader();
        const logoutResponse = await authAPI.logout(auth.userType);
        if (logoutResponse.errors) {
            notify({
                type: NotificationType.ERROR,
                message: logoutResponse.body.Errors[0].Message
            });
        } else {
            notify({
                type: NotificationType.SUCCESS,
                message: "Zostałeś wylogowany"
            });
            logoutUser();
            history.push(Routes.INDEX);
        }
        hideLoader();
    }

    return (
        <>
            <div id="header">
                <div id="navbar">
                    <nav className="navbar">
                        <ul className="list">
                            <li className="item">
                                <Link className="logo" to={Routes.INDEX} />
                            </li>

                            {(!nonAuthPathsMatch || auth.isLoggedIn) && (
                                <>
                                    {isUserClient && (
                                        <li className="item">
                                            <Link className="link" to={Routes.PROJECT_TEMPLATES}>
                                                Projektowanie
                                            </Link>
                                        </li>
                                    )}

                                    {(isUserClient || isUserAdmin) && (
                                        <li className="item">
                                            <Link className="link" to={Routes.ORDERS}>
                                                Zamówienia
                                            </Link>
                                        </li>
                                    )}

                                    {isUserAdmin && (
                                        <li className="item">
                                            <Link className="link" to={Routes.ADMIN_CLIENTS}>
                                                Klienci
                                            </Link>
                                        </li>
                                    )}

                                    {isUserClient && (
                                        <>
                                            <li className="item">
                                                <Link className="link" to={Routes.CLIENT_EDIT}>
                                                    Edytuj konto
                                                </Link>
                                            </li>

                                            <li className="item">
                                                <Link
                                                    className="link"
                                                    to={Routes.CLIENT_PASSWORD_CHANGE}
                                                >
                                                    Zmień hasło
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                    
                                    {auth.isLoggedIn && (
                                        <li className="item">
                                            <a className="link" href="logout" onClick={handleLogout} >
                                                Wyloguj
                                            </a>
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                    </nav>
                </div>
                {(nonAuthPathsMatch && !auth.isLoggedIn) && (
                    <LoginForm />
                )}
            </div>
        </>
    );
};
