export const ListItem = (props: any) => {
    const { dataItem, ...rest } = props;
    const { OrderNumber, OrderNumber2, ClientName, ClientContactMobile } = dataItem;


    return (
        <div {...rest} className="summary">
            <header>
                <h2>
                    {OrderNumber}
                    <br />{OrderNumber2}
                </h2>
            </header>

            <div className="details">
                <p>
                    {ClientName}
                </p>

                <p>
                    <a href="#">
                        {ClientContactMobile}
                    </a>
                </p>
            </div>
        </div>
    );
};