import { useEffect, useState } from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { DoorMeasuresListProps, MeasuresState, Measure } from "./DoorMeasuresList.types";
import {
    Input,
    InputChangeEvent,
    NumericTextBox,
    NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Measures, MeasuresPayload, projectAPI, ProjectDoor, ProjectJoin } from "api";
import { useProject } from "hooks";


const defaultMeasure = { a: null, b: null };


function mapMeasuresToPayload(measures: MeasuresState, orientation: "vertical" | "horizontal" = "horizontal"): MeasuresPayload {
    if (orientation === "horizontal") {
        const reduced = measures.reduce<Measures>(
            (result, { a, b }) => ({
                ...result,
                MeasuresLeft: [...result.MeasuresLeft, { MeasureLength: a }],
                MeasuresRight: [...result.MeasuresRight, { MeasureLength: b }],
            }),
            {
                MeasuresTop: [],
                MeasuresLeft: [],
                MeasuresRight: [],
                MeasuresBottom: [],
            }
        );

        return {
            Project: {
                Door: reduced,
            },
        };
    }

    const reduced = measures.reduce<Measures>(
        (result, { a, b }) => ({
            ...result,
            MeasuresTop: [...result.MeasuresTop, { MeasureLength: a }],
            MeasuresBottom: [...result.MeasuresBottom, { MeasureLength: b }],
        }),
        {
            MeasuresTop: [],
            MeasuresLeft: [],
            MeasuresRight: [],
            MeasuresBottom: [],
        }
    );

    return {
        Project: {
            Door: reduced,
        },
    };
}


function filterJoinsByDoor(joins: ProjectJoin[], door: ProjectDoor) {
    return joins.filter(({ DoorId }) => DoorId === door.DoorId);
}


export const DoorMeasuresListComponent = ({
    selectedDoor,
    joins,
    orientation = "horizontal",
    onClose,
}: DoorMeasuresListProps) => {
    const { project, handleProjectRequest } = useProject();
    const [measures, setMeasures] = useState<MeasuresState>([]);
    const emptyMeasureRows = measures.filter((measure) => measure.a === null);


    /**
     * Pobiera czy wartość miary może być wyłączona
     * @param index Indeks sprawdzanej wartości miary
     */
    function getMeasure1Disabled(index: number) {
        if (
            measures[index].a !== null
        ) {
            return false;
        }
        else {
            const emptyMeasures = measures.filter((measure) => measure.a === null);


            if (
                emptyMeasures.length === 1
            ) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    /**
     * Pobiera czy wartość miary może być wyłączona
     * @param index Indeks sprawdzanej wartości miary
     */
     function getMeasure2Disabled(index: number) {
        if (
            measures[index].b !== null
        ) {
            return false;
        }
        else {
            const emptyMeasures = measures.filter((measure) => measure.b === null);


            if (
                emptyMeasures.length === 1
            ) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    function handleChange(index: number, name: string) {
        return (event: NumericTextBoxChangeEvent) => {
            setMeasures((measures) =>
                measures.map((measure, i) => {
                    if (i === index) {
                        return {
                            a: ("a" === name ? event.value : measure.a),
                            b: ("b" === name ? event.value : measure.b),
                        };
                    }

                    return measure;
                })
            );
        };
    }


    async function handleSubmit() {
        if (project && selectedDoor) {
            const updatePayload = mapMeasuresToPayload(measures, orientation);

            handleProjectRequest(async () => {
                const res = await projectAPI.updateJoinsMeasures(
                    project.Project.ProjectId,
                    selectedDoor.DoorId,
                    updatePayload
                );
                onClose();

                return res;
            });
        }
    }


    useEffect(() => {
        const joinsWithinDoor = filterJoinsByDoor(joins, selectedDoor);


        const measures = [];
        measures.push({
            a: null,
            b: null
        });
        for (var i = 0; i < joinsWithinDoor.length; i++) {
            measures.push({
                a: null,
                b: null
            });
        }

        setMeasures(measures);
    }, [joins, selectedDoor]);


    useEffect(() => {
        if(emptyMeasureRows.length === 0 && measures.length > 0) {
            setMeasures((measures) => [...measures.slice(0, -1), defaultMeasure]);
        }
    }, [measures]);


    return (
        <Window title={"Wprowadź wymiary między łącznikami"} width={960} height={undefined} onClose={onClose}>
        { (orientation === "horizontal") ? <div className="measuresvalue measureshorizontalvalue">
                <table>
                { measures.map(({ a, b }, index) => (
                    <tr key={index}>
                        <td>
                            <NumericTextBox className="left" value={a} disabled={getMeasure1Disabled(index)} onChange={handleChange(index, "a")} />
                            <NumericTextBox className="right" value={b} disabled={getMeasure2Disabled(index)} onChange={handleChange(index, "b")} />
                        </td>
                    </tr>
                )) }
                </table>
            </div>: <div className="measuresvalue measuresverticalvalue">
                <table>
                    <tr>
                    { measures.map(({ a, b }, index) => (
                        <td key={index}>
                            <NumericTextBox className="top" value={a} disabled={getMeasure1Disabled(index)} onChange={handleChange(index, "a")} />
                            <NumericTextBox className="bottom" value={b} disabled={getMeasure2Disabled(index)} onChange={handleChange(index, "b")} />
                        </td>
                    )) }
                    </tr>
                </table>
            </div> }

            <WindowActionsBar>
                <button type="button" className="k-button k-primary" onClick={handleSubmit}>
                    Ustaw
                </button>
            </WindowActionsBar>
        </Window>
    );
};
