import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { projectAPI, ordersAPI } from 'api';
import { NotificationType, useNotifications } from 'hooks';
import { useState } from 'react';
import { SummaryProps } from './Summary.types';
import { useHistory } from 'react-router-dom';

export const Summary = ({ summary, onClose }: SummaryProps) => {
	const { Project, Order } = summary;
	const history = useHistory();
	const { notify } = useNotifications();
	const [note, setNote] = useState("");
	const [orderNumber, setOrderNumber] = useState("");

	async function handleOrder() {
		if(Order) {
			const response = await ordersAPI.placeOrder(Order?.OrderId, orderNumber, note);

			if(response?.errors) {
                notify({
                    type: NotificationType.ERROR,
                    message: response.body.Errors[0].Message,
                });
			} else {
				notify({
					type: NotificationType.SUCCESS,
					message: "Poprawnie złożono zamówienie"
				});
				history.push(`/orders?open=${Order.OrderId}`);
			}
		}
	}

    return (
        <Window
                    title="Podsumowanie"
					width={960}
                    height={undefined}
                    onClose={onClose}
                >
					<div className="summary">
						<div className="properties">
							<dl>
								{/* <dt className="type">Typ drzwi:</dt>
								<dd>Przesuwne</dd>

								<dt className="name">Nazwa:</dt>
								<dd>Pluton</dd> */}

								<dt className="width">Szerokość:</dt>
								<dd>{Project?.Properties.Width.Width}</dd>

								<dt className="height">Wysokość:</dt>
								<dd>{Project?.Properties.Height.Height}</dd>

								<dt className="doors">Ilość drzwi:</dt>
								<dd>{Project?.Properties.Doors.Doors}</dd>
								
							{Project?.Properties.Side && (
								<>
									<dt className="side">Strona:</dt>
									<dd>{Project?.Properties.Side.Name}</dd>
								</>
							)}

							{Project?.Properties.Color && (
								<>
									<dt className="colorid">Kolor:</dt>
									<dd>{Project?.Properties.Color.Name}</dd>
								</>
							)}
							</dl>
						</div>

						<div className="textures">
							<table>
								<thead>
									<tr>
										<th>Wypełnienia</th>
									</tr>
								</thead>

								<tbody>
									{Project?.Forms.map(({ ProductName }) =>(
									<tr>
										<td>{ProductName}</td>
									</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="pricing">
							<table>
								<thead>
									<tr>
										<th colSpan={2}>Wycena</th>
									</tr>
								</thead>

								<tbody>
								{Order?.OrderPriceNet && (
									<tr>
										<th>Cena netto:</th>
										<td>
											{Order?.OrderPriceNet.toFixed(2)}
										</td>
									</tr>
								)}

								{Order?.OrderPrice && (
									<tr>
										<th>Cena brutto:</th>
										<td>
											{Order?.OrderPrice.toFixed(2)}
										</td>
									</tr>
								)}

								{Order?.OrderDiscount && (
									<tr>
										<th>Rabat:</th>
										<td>
											{Order?.OrderDiscount.toFixed(2)}
										</td>
									</tr>
								)}

								{Order?.OrderFinalPriceNet && (
									<tr>
										<th>Cena netto po rabacie:</th>
										<td>
											{Order?.OrderFinalPriceNet.toFixed(2)}
										</td>
									</tr>
								)}

								{Order?.OrderFinalPrice && (
									<tr>
										<th>Cena brutto po rabacie:</th>
										<td>
											{Order?.OrderFinalPrice.toFixed(2)}
										</td>
									</tr>
								)}
								</tbody>
							</table>
						</div>

						<div className="order">
							<form>
								<fieldset>
									<legend>Zamówienie</legend>

									<div className="number2">
										<label>Twój numer zamówienia:</label>
										<input value={orderNumber} onInput={({ currentTarget }) => setOrderNumber(currentTarget.value)} />
									</div>

									<div className="note">
										<label>Uwagi:</label>
										<textarea onInput={({ currentTarget }) => setNote(currentTarget.value)}>{note}</textarea>
									</div>
								</fieldset>
							</form>
						</div>
					</div>

              		<WindowActionsBar>
                		<button type="button" className="k-button k-primary" onClick={handleOrder}>Złóż zamówienie</button>
              		</WindowActionsBar>
                </Window>
    )
}