export enum UserType {
    CLIENT = "client",
    ADMIN = "admin",
}

export type User = {
    UserName: string;
    Password: string;
}

export type Password = {
    Password: {
        ClientNowPassword: string;
        ClientNewPassword: string;
    }
}
