import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import {
    Summary as OrderSummary,
    Project,
    projectAPI,
    ProjectForm,
    ProjectDoor,
    ProjectJoin,
    ListableProperties,
    AvailableProperties,
    UserType,
    ordersAPI,
} from "api";
import { Summary, DoorsComponent } from "components";
import { ResponseStatus } from "api/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NotificationType, useLoader, useNotifications, useProject } from "hooks";
import { NumericChangeEvent, PropertySchema, SelectChangeEvent } from "./Project.types";
import { useAuth } from "hooks";
import { PropertyComponent } from "../Property/Property.component";
import { DoorMeasuresListComponent } from "../DoorMeasuresList/DoorMeasuresList.component";

export const ProjectComponent = () => {
    const { isUserAdmin, isUserClient } = useAuth();
    const { notify } = useNotifications();
    const { showLoader, hideLoader } = useLoader();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { search } = useLocation();
    const [summary, setSummary] = useState<OrderSummary>();
    const [isSummaryVisible, setIsSummaryVisible] = useState(false);
    const [selectedDoor, setSelectedDoor] = useState<ProjectDoor>();
    const [selectedJoins, setSelectedJoins] = useState<ProjectJoin[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<PropertySchema>();
    const [selectedForm, setSelectedForm] = useState<ProjectForm>();
    const [joinAligmentWindow, setJoinAligmentWindow] = useState({
        visible: false,
        orientation: "horizontal" as "horizontal" | "vertical",
    });
    const { project, handleProjectRequest, handleProjectPropertyRequest } = useProject();
    const [isInitWindowVisible, setIsInitWindowVisible] = useState(false);
    const [projectSchema, setProjectSchema] = useState<Array<PropertySchema>>([]);

    useEffect(() => {
        if (project) {
            setProjectSchema([
                {
                    key: "Doors",
                    linkClassName: "tool initializedoors",
                    linkLabel: "Ilość drzwi",
                    windowProps: {
                        title: "Ilość drzwi",
                        property: {
                            type: "numeric",
                            defaultValue: project.Project.Properties.Doors.Doors,
                            onChange: handlePropertyUpdateWithValue("Doors"),
                        },
                    },
                },
                {
                    key: "Width",
                    linkClassName: "tool initializewidth",
                    linkLabel: "Szerokość frontu",
                    windowProps: {
                        title: "Szerokość",
                        property: {
                            type: "numeric",
                            defaultValue: project.Project.Properties.Width.Width,
                            onChange: handlePropertyUpdateWithValue("Width"),
                        },
                    },
                },
                {
                    isDisabled: selectedDoor === undefined,
                    disabledReason: "Zaznacz drzwi",
                    linkClassName: "tool initializedoorwidth",
                    linkLabel: "Szerokość drzwi",
                    windowProps: {
                        title: "Szerokość drzwi",
                        property: {
                            type: "numeric",
                            defaultValue: selectedDoor?.DoorSize.Width ?? 0,
                            onChange: ({ value }) =>
                                selectedDoor &&
                                value &&
                                handleProjectRequest(() =>
                                    projectAPI.updateDoorWidth(
                                        project.Project.ProjectId,
                                        selectedDoor.DoorId,
                                        value
                                    )
                                ),
                        },
                    },
                },
                {
                    key: "Height",
                    linkClassName: "tool initializeheight",
                    linkLabel: "Wysokość frontu",
                    windowProps: {
                        title: "Wysokość frontu",
                        property: {
                            type: "numeric",
                            defaultValue: project.Project.Properties.Height.Height,
                            onChange: handlePropertyUpdateWithValue("Height"),
                        },
                    },
                },
                {
                    key: "Side",
                    isHidden: !project.Project.Properties.Side,
                    linkClassName: "tool initializeside",
                    linkLabel: "Strona",
                    windowProps: {
                        title: "Strona",
                        property: genericSelectProperty("Side"),
                    },
                },
                {
                    key: "Color",
                    isHidden: !project.Project.Properties.Color,
                    linkClassName: "tool initializecolorid",
                    linkLabel: "Kolor",
                    windowProps: {
                        title: "Kolor",
                        property: genericSelectProperty("Color"),
                    },
                },
                {
                    key: "Overlap",
                    isHidden: !project.Project.Properties.Overlap,
                    linkClassName: "tool initializeoverlap",
                    linkLabel: "Zakładka",
                    windowProps: {
                        title: "Zakładka",
                        property: genericSelectProperty("Overlap"),
                    },
                },
                {
                    key: "SoftClose",
                    isHidden: !project.Project.Properties.SoftClose,
                    linkClassName: "tool initializesoftcloseid",
                    linkLabel: "Samodomykacz drzwi skrajnych",
                    windowProps: {
                        title: "Samodomykacz drzwi skrajnych",
                        property: genericSelectProperty("SoftClose"),
                    },
                },
                {
                    key: "SoftCloseInside",
                    isHidden: !project.Project.Properties.SoftCloseInside,
                    linkClassName: "tool initializesoftcloseinsideid",
                    linkLabel: "Samodomykacz drzwi środkowych",
                    windowProps: {
                        title: "Samodomykacz drzwi środkowych",
                        property: genericSelectProperty("SoftCloseInside"),
                    },
                },
                {
                    key: "SoftCloseCenter",
                    isHidden: !project.Project.Properties.SoftCloseCenter,
                    linkClassName: "tool initializesoftclosecenterid",
                    linkLabel: "Samodomykacz centralny",
                    windowProps: {
                        title: "Samodomykacz centralny",
                        property: genericSelectProperty("SoftCloseCenter"),
                    },
                },
                {
                    key: "TrackUpper",
                    isHidden: !isUserAdmin || !project.Project.Properties.TrackUpper,
                    linkClassName: "tool initializetrackupperid",
                    linkLabel: "Tor górny",
                    windowProps: {
                        title: "Tor górny",
                        property: genericSelectProperty("TrackUpper"),
                    },
                },
                {
                    key: "TrackLower",
                    isHidden: !isUserAdmin || !project.Project.Properties.TrackLower,
                    linkClassName: "tool initializetracklowerid",
                    linkLabel: "Tor dolny",
                    windowProps: {
                        title: "Tor dolny",
                        property: genericSelectProperty("TrackLower"),
                    },
                },
                {
                    key: "ProfileUpper",
                    isHidden: !isUserAdmin || !project.Project.Properties.ProfileUpper,
                    linkClassName: "tool initializeprofileupperid",
                    linkLabel: "Porfil górny",
                    windowProps: {
                        title: "Porfil górny",
                        property: genericSelectProperty("ProfileUpper"),
                    },
                },
                {
                    key: "ProfileLower",
                    isHidden: !isUserAdmin || !project.Project.Properties.ProfileLower,
                    linkClassName: "tool initializeprofilelowerid",
                    linkLabel: "Profil dolny",
                    windowProps: {
                        title: "Profil dolny",
                        property: genericSelectProperty("ProfileLower"),
                    },
                },
                {
                    key: "CarriageUpper",
                    isHidden: !isUserAdmin || !project.Project.Properties.CarriageUpper,
                    linkClassName: "tool initializecarriageupperid",
                    linkLabel: "Wózek górny",
                    windowProps: {
                        title: "Wózek górny",
                        property: genericSelectProperty("CarriageUpper"),
                    },
                },
                {
                    key: "CarriageLower",
                    isHidden: !isUserAdmin || !project.Project.Properties.CarriageLower,
                    linkClassName: "tool initializecarriagelowerid",
                    linkLabel: "Wózek dolny",
                    windowProps: {
                        title: "Wózek dolny",
                        property: genericSelectProperty("CarriageLower"),
                    },
                },
                {
                    key: "BrushBumper",
                    isHidden: !isUserAdmin || !project.Project.Properties.BrushBumper,
                    linkClassName: "tool initializebrushbumperid",
                    linkLabel: "Szczotka odbojowa",
                    windowProps: {
                        title: "Szczotka odbojowa",
                        property: genericSelectProperty("BrushBumper"),
                    },
                },
                {
                    key: "BrushNoDust",
                    isHidden: !isUserAdmin || !project.Project.Properties.BrushNoDust,
                    linkClassName: "tool initializebrushnodustid",
                    linkLabel: "Szczotka przeciwkurzowa",
                    windowProps: {
                        title: "Szczotka przeciwkurzowa",
                        property: genericSelectProperty("BrushNoDust"),
                    },
                },
                {
                    isDisabled: selectedDoor === undefined,
                    disabledReason: "Zaznacz drzwi na których ma być dodany łącznik",
                    linkClassName: "tool addjoin",
                    linkLabel: "Dodanie łącznika",
                    onClick: handleDoorAction(({ DoorId }) =>
                        projectAPI.addJoin(project.Project.ProjectId, DoorId)
                    ),
                },
                {
                    isDisabled: selectedJoins.length !== 1,
                    disabledReason: "Zaznacz łącznik, który chcesz usunąć",
                    linkClassName: "tool removejoin",
                    linkLabel: "Usunięcie łącznika",
                    onClick: handleJoinAction(({ JoinId, DoorId }) => {
                        setSelectedJoins((joins) => joins.filter((j) => j.JoinId !== JoinId));
                        return projectAPI.removeJoin(project.Project.ProjectId, DoorId, JoinId);
                    }),
                },
                {
                    isDisabled: selectedJoins.length !== 1,
                    linkClassName: "tool initializejoinprofile",
                    disabledReason: "Zaznacz łącznik, który chcesz zmienić",
                    linkLabel: "Wybranie rodzaju łącznika",
                    windowProps: {
                        title: "Profil",
                        property: {
                            type: "select",
                            getOptions: handleJoinFetch(({ JoinId }) =>
                                projectAPI.getProfiles(project!.Project.ProjectId, JoinId)
                            ),
                            defaultOption: {
                                Id: selectedJoins[0]?.ProductId,
                                Name: selectedJoins[0]?.ProductName,
                            },
                            onChange: ({ value }) =>
                                handleProjectRequest(() =>
                                    projectAPI.updateProfile(
                                        project.Project.ProjectId,
                                        selectedJoins[0].JoinId,
                                        value.Id
                                    )
                                ),
                        },
                    },
                },
                {
                    isDisabled: selectedForm === undefined,
                    disabledReason: "Zaznacz wypełnienie, które chcesz zmienić",
                    linkClassName: "tool initializeformtexture",
                    linkLabel: "Wybranie rodzaju formatki",
                    windowProps: {
                        title: "Wypełnienie",
                        property: {
                            type: "catalog",
                            defaultOptionId: selectedForm?.ProductId,
                            getOptions: () =>
                                handleFetch(() =>
                                    projectAPI.getFormTextures(
                                        project.Project.ProjectId,
                                        selectedForm!.FormId
                                    )
                                ),
                            onChange: (product) =>
                                handleProjectRequest(() =>
                                    projectAPI.updateFormTexture(
                                        project.Project.ProjectId,
                                        selectedForm!.FormId,
                                        product.Id
                                    )
                                ),
                        },
                    },
                },
                {
                    isDisabled: selectedDoor === undefined,
                    disabledReason: "Zaznacz drzwi na których ma być dodany łącznik poziomy",
                    linkClassName: "tool modifyjoinmovehorizontal",
                    linkLabel: "Ułożenie łączników poziomo",
                    onClick: () =>
                        setJoinAligmentWindow({ visible: true, orientation: "horizontal" }),
                },
                {
                    isDisabled: selectedDoor === undefined,
                    disabledReason: "Zaznacz drzwi na których ma być dodany łącznik pionowy",
                    linkClassName: "tool modifyjoinmovevertical",
                    linkLabel: "Ułożenie łączników pionowo",
                    onClick: () =>
                        setJoinAligmentWindow({ visible: true, orientation: "vertical" }),
                },
                {
                    isDisabled: selectedJoins.length < 2,
                    isHidden: !isUserAdmin,
                    disabledReason: "Zaznacz przynajmniej dwa łączniki",
                    linkClassName: "tool modifyjoinmerge",
                    linkLabel: "Ułożenie łączników w linii",
                    onClick: handleJoinsMerge(),
                },
                {
                    isDisabled: selectedJoins.length < 2,
                    isHidden: !isUserAdmin,
                    disabledReason: "Zaznacz przynajmniej dwa łączniki",
                    linkClassName: "tool modifyjoinmergehorizontal",
                    linkLabel: "Ułożenie łączników w linii poziomo",
                    onClick: handleJoinsMerge("horizontal"),
                },
                {
                    isDisabled: selectedJoins.length < 2,
                    isHidden: !isUserAdmin,
                    disabledReason: "Zaznacz przynajmniej dwa łączniki",
                    linkClassName: "tool modifyjoinleftmove",
                    linkLabel: "Ułożenie łączników do prawego",
                    onClick: handleMoveJoins("Right"),
                },
                {
                    isDisabled: selectedJoins.length < 2,
                    isHidden: !isUserAdmin,
                    disabledReason: "Zaznacz przynajmniej dwa łączniki",
                    linkClassName: "tool modifyjoinrightmove",
                    linkLabel: "Ułożenie łączników do lewego",
                    onClick: handleMoveJoins("Left"),
                },
                {
                    isDisabled: selectedForm === undefined,
                    disabledReason: "Zaznacz wypełnienie, które chcesz zmienić",
                    linkClassName: "tool modifyformhorizontal",
                    linkLabel: "Ustawienie usłojenia poziome",
                    onClick: handleFormOrientationChange(2),
                },
                {
                    isDisabled: selectedForm === undefined,
                    disabledReason: "Zaznacz wypełnienie, które chcesz zmienić",
                    linkClassName: "tool modifyformvertical",
                    linkLabel: "Ustawienie usłojenia pionowo",
                    onClick: handleFormOrientationChange(1),
                },
                {
                    linkClassName: "tool addorder",
                    linkLabel: "Podsumowanie",
                    onClick: async () => {
                        setIsSummaryVisible(true);
                        const searchParams = new URLSearchParams(search);
                        const clientId = searchParams.get("clientId");
                        const order =
                            (await handleFetch(() =>
                                ordersAPI.estimateOrder(project.Project.ProjectId, clientId)
                            )) ?? {};
                        setSummary({
                            ...order,
                            ...project,
                        });
                    },
                },
            ]);
        }
    }, [project?.Project.ProjectId, selectedDoor, selectedJoins, selectedForm]);

    function getSelectedJoinsId() {
        return selectedJoins.map(({ JoinId }) => JoinId);
    }

    type Request<T, N> = (arg: T) => Promise<ResponseStatus<N>> | undefined;

    async function handleFetch(request: Request<any, any>, arg?: any) {
        showLoader();

        const response = await request(arg);
        if (response && !response.errors) {
            hideLoader();
            return response.body;
        }

        hideLoader();
    }

    function handleDoorAction(request: Request<ProjectDoor, Project>) {
        return () => selectedDoor && handleProjectRequest(request, selectedDoor);
    }

    function handleFormAction(request: Request<ProjectForm, Project>) {
        return () => selectedForm && handleProjectRequest(request, selectedForm);
    }

    function handleJoinAction(request: Request<ProjectJoin, Project>) {
        return () => {
            if (selectedJoins && selectedJoins.length === 1) {
                handleProjectRequest(request, selectedJoins[0]);
            }
        };
    }

    function handleJoinFetch(request: Request<ProjectJoin, AvailableProperties>) {
        return () => {
            if (selectedJoins && selectedJoins.length === 1) {
                return handleFetch(request, selectedJoins[0]);
            }
        };
    }

    function handleFormOrientationChange(orientation: number) {
        return handleFormAction(
            (form) =>
                project &&
                projectAPI.updateFormOrientation(
                    project.Project.ProjectId,
                    form.FormId,
                    orientation
                )
        );
    }

    function handleJoinOrientationChange(orientation: number) {
        return handleDoorAction(
            (door) =>
                project &&
                projectAPI.updateJoinOrientation(
                    project.Project.ProjectId,
                    door.DoorId,
                    orientation
                )
        );
    }

    function handlePropertyUpdateWithValue(name: string) {
        return async (event: NumericChangeEvent) => {
            if (event.value) {
                return handleProjectPropertyRequest({
                    request: () =>
                        project &&
                        projectAPI.updatePropertyWithValue(
                            project.Project.ProjectId,
                            name,
                            event.value as number
                        ),
                });
            }
        };
    }

    function handlePropertyUpdate(name: ListableProperties) {
        return async (event: SelectChangeEvent) => {
            if (event.value) {
                return handleProjectPropertyRequest({
                    request: () =>
                        project &&
                        projectAPI.updateProperty(
                            project.Project.ProjectId,
                            name,
                            event.value.Id || event.value.Side || event.value.Overlap
                        ),
                });
            }
        };
    }

    function handleJoinsMerge(direction?: string) {
        return () =>
            handleProjectRequest(
                () =>
                    project &&
                    projectAPI.mergeJoins(
                        project.Project.ProjectId,
                        getSelectedJoinsId(),
                        direction === "horizontal"
                    )
            );
    }

    function handleMoveJoins(direction: string) {
        return () =>
            handleProjectRequest(
                () =>
                    project &&
                    projectAPI.moveJoins(project.Project.ProjectId, getSelectedJoinsId(), direction)
            );
    }

    function genericSelectProperty(key: ListableProperties) {
        return {
            type: "select" as "select",
            getOptions: () =>
                handleFetch(() => projectAPI.getProperties(project!.Project.ProjectId, key)),
            defaultOption: project!.Project.Properties[key] as any,
            onChange: handlePropertyUpdate(key),
        };
    }

    useEffect(() => {
        handleProjectRequest(async () => {
            const projectResponse = await projectAPI.get(id);
            const searchParams = new URLSearchParams(search);
            const withInit = searchParams.get("init");

            if (withInit === "true" && !projectResponse.errors) {
                setIsInitWindowVisible(true);
            }

            return projectResponse;
        });
    }, []);

    useEffect(() => {
        if (project) {
            const searchParams = new URLSearchParams(search);
            const clientId = searchParams.get("clientId");
            clientId
                ? history.replace(`/project/${project.Project.ProjectId}?clientId=${clientId}`)
                : history.replace(`/project/${project.Project.ProjectId}`);
            setSelectedForm((currentForm) => {
                const updatedForm = project.Project.Forms.find(
                    ({ FormId }) => FormId === currentForm?.FormId
                );

                return updatedForm;
            });
        }
    }, [project?.Project.ProjectId]);

    useEffect(() => {
        const findDoor = (doorId: string) =>
            project?.Project.Doors.find(({ DoorId }) => doorId === DoorId);

        if (selectedForm) {
            setSelectedDoor(findDoor(selectedForm.DoorId));
        } else if (selectedJoins.length) {
            const firstJoinDoorId = selectedJoins[0].DoorId;
            const isEverySelectedJoinWithinSameDoor = selectedJoins.every(
                ({ DoorId }) => DoorId === firstJoinDoorId
            );

            if (isEverySelectedJoinWithinSameDoor) {
                setSelectedDoor(findDoor(firstJoinDoorId));
            } else {
                setSelectedDoor(undefined);
            }
        } else {
            setSelectedDoor(undefined);
        }
    }, [selectedForm, selectedJoins]);

    return (
        <>
            {project && (
                <div id="project">
                    <div className="toolbartray">
                        <div className="toolbar">
                            {projectSchema.map(
                                (property) =>
                                    !property.isHidden && (
                                        <div
                                            className={property.linkClassName}
                                            key={property.linkLabel}
                                            onClick={() => {
                                                if (!property.isDisabled) {
                                                    setSelectedProperty(property);
                                                    property.onClick && property.onClick();
                                                } else {
                                                    notify({
                                                        type: NotificationType.ERROR,
                                                        message:
                                                            property.disabledReason ??
                                                            "Stan projektu nie pozwala użyć tej opcji",
                                                    });
                                                }
                                            }}
                                        >
                                            <a>{property.linkLabel}</a>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>

                    {selectedDoor && joinAligmentWindow.visible && (
                        <DoorMeasuresListComponent
                            joins={project.Project.Joins}
                            selectedDoor={selectedDoor}
                            orientation={joinAligmentWindow.orientation}
                            onClose={() =>
                                setJoinAligmentWindow({ visible: false, orientation: "horizontal" })
                            }
                        />
                    )}

                    {isInitWindowVisible && (selectedProperty || projectSchema[0]) && (
                        <PropertyComponent
                            withTabs
                            selectedProperty={selectedProperty || projectSchema[0]}
                            setSelectedProperty={setSelectedProperty}
                            onClose={() => {
                                setIsInitWindowVisible(false);
                                setSelectedProperty(undefined);
                            }}
                            properties={projectSchema.filter(
                                ({ key, isHidden }) => !isHidden && !!key
                            )}
                        />
                    )}

                    {!isInitWindowVisible && selectedProperty && selectedProperty.windowProps && (
                        <PropertyComponent
                            selectedProperty={selectedProperty}
                            onClose={() => setSelectedProperty(undefined)}
                        />
                    )}

                    {isSummaryVisible && summary && (
                        <Summary summary={summary} onClose={() => setIsSummaryVisible(false)} />
                    )}

                    {project && (
                        <DoorsComponent
                            {...{ selectedJoins, selectedForm, setSelectedJoins, setSelectedForm }}
                            project={project}
                        />
                    )}
                </div>
            )}
        </>
    );
};
