export enum Routes {
    /**
     * Widok startowy, dostępny bez logowania i po zalogowaniu klienta i administratora.
     * Widok formularza logowania klienta. Po zalogowaniu otwiera się ten sam widok z dostępnym menu.
    */
    INDEX = "/",

    /**
     * Widok dostępny po zalogowaniu administratora.
     * Widok formularza listy klientów.
    */
    ADMIN_CLIENTS = "/clients",


    /**
     * Widok dostępny po zalogowaniu administratora.
     * Widok formularza edycji klienta wybranego z poziomu listy klientów.
    */
    ADMIN_CLIENT_EDIT = "/clients/:id",


    /**
     * Widok dostępny po zalogowaniu administratora.
     * Widok formularza edycji rabatu w kliencie wybranego z poziomu listy klientów.
    */
    ADMIN_CLIENT_DISCOUNT = "/clients/:id/discount",


    /**
     * Widok dostępny po zalogowaniu administratora.
     * Widok formularza edycji statusu w kliencie wybranego z poziomu listy klientów.
    */
    ADMIN_CLIENT_STATUS = "/clients/:id/status",


    /**
     * Widok dostępny bez logowania i po zalogowaniu klienta.
     * Widok formularza zakładania klienta lub edycji klienta jeżeli klient jest zalogowany.
    */
    CLIENT_EDIT = "/client",


    /**
     * Widok dostępny bez logowania.
     * Widok formularza resetowania hasła.
    */
    CLIENT_PASSWORD_GENERATE = "/client/password/generate",


    /**
     * Widok dostępny po zalogowaniu klienta.
     * Widok formularza zmiany hasła.
    */
    CLIENT_PASSWORD_CHANGE = "/client/password",


    /**
     * Widok formularza listy szablonów projektów.
     */
    PROJECT_TEMPLATES = "/templates",
    

    /**
     * Widok dostępny po zalogowaniu klienta.
     * Widok formularza listy szablonów drzwi przesuwnych.
    */

    PROJECT_TEMPLATES_SLIDING = "/templates/slidings",
    

    /**
     * Widok dostępny po zalogowaniu klienta.
     * Widok formularza listy szablonów drzwi składanych.
    */
    PROJECT_TEMPLATES_FOLDING = "/templates/folding",


    /**
     * Widok dostępny po zalogowaniu klienta.
     * Widok formularza listy szablonów drzwi rozwieranych.
    */
    PROJECT_TEMPLATES_PULLAPART = "/templates/pullapart",


    /**
     * Widok dostępny po zalogowaniu klienta.
     * Widok formularza listy szablonów drzwi przejściowych.
    */
    PROJECT_TEMPLATES_PATHWAY = "/templates/pathway",


    /**
     * Widok formularza listy zamówień.
     */
    ORDERS = "/orders",


    /**
     * Widok formularza projektu.
     */
    PROJECT = "/project/:id"
}
